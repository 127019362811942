import React from 'react'

const AdminMasterFooter = () => {
  const footerStyle = {
    backgroundColor:"#462f46",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
  return (
    <>
      <div style={footerStyle} className='mt-5'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default AdminMasterFooter
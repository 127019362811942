import React, { useEffect, useRef, useState } from 'react'
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import { Col, Form, Row, Table } from 'react-bootstrap'
import { EducationMaster } from '../AllApi/UserApi/UserApi'
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { reportDataPDF } from '../AllApi/AdminApi/AdminApi'
import { toast } from 'react-toastify'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useReactToPrint } from 'react-to-print'

const AdminReports = () => {

  const [Userdata, setUserdata] = useState()
  const [gender,setGender] = useState()
  const [melava,setMelava] = useState()
  const [Maritialsatatus,setMaritialsatatus] = useState()
  const [Disability,setDisability] = useState()
  const [Category,setCategory] = useState()
  const [srNo,setSrNo] = useState()
  const [fromNo,setFromNo] = useState()
  const [TotalNo,setTotalNo] = useState()
  const [EducationData, setEducationData] = useState([]);
  const [showTableData,setShowTableData] = useState(false)

  // useEffect(() => {
  //   Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
  //   }
  //   )

  // }, [Userdata])


  const getUserData = (category) => {
    axios.get(`${reportDataPDF}/${melava}/${gender}/${category}`)
      .then((res) => {
        console.log('===========res1===========',res);
        if (res.data === "NOTFOUND") {
          toast.error("Data Not Found", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
          // alert("Data Not Found")
        }
        else{
            setTotalNo(res.data)
        }
        
      })
      .catch((err) => {
        console.log(err)
      })

      setCategory(category)
  }

  // ==== education api =====

  const getEducationData = () => {
    axios
      .get(EducationMaster)
      .then((res) => {
        console.log('===========res2===========',res);
        if (res.data !== null) {
          setEducationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const HandleSaveFun =()=>{
    
    axios.get(`${reportDataPDF}/${melava}/${gender}/${Category}/${srNo}`)
      .then((res) => {
        console.log('===========reportDataPDF===========',res.data);
        if (res.data === "NOTFOUND") {
          toast.error("Data Not Found", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
        }
        else{
         const data=res.data
            setUserdata(data) 
            console.log('============setUserdata==========',Userdata);
        }
      })
      .catch((err) => {
        console.log(err)
      })
    
    setShowTableData(true)
  }


  useEffect(() => {
      getEducationData();
  }, [])

    // ---------------print-----
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Payment_Recipt',
      // onAfterPrint: () => alert('Print Success')
    })
  
    const pdfOptions = {
      margin: 10,
      filename: 'Payment_Recipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { before: '.page-break' }, // Use this class to force page breaks
    };
  
    // Select the content to print (excluding buttons)
    const contentToPrint = document.getElementById('content-to-print');
  
    // Generate the PDF
    html2pdf().from(contentToPrint).set(pdfOptions).outputPdf((pdf) => {
      // Save the PDF to the user's PC
      saveAs(pdf, 'Payment_Recipt.pdf');
    });

  //--------------------------CSS---------------------------------
  const footerStyle = {
    backgroundColor: '#462f46',
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '12px',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }
  // const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "12px" }
  const style = { fontSize: "12px", width: "100%", padding: "2px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "3px 5px", alignItems: "center", height: "65px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style6 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "12px", fontSize: "12px", justifyContent: "center", flexDirection: "column" }

  return (
    <>
      <div> <AdminMasterHeader/> </div>
     
     <Row className="m-0 p-0 px-md-5 mx-md-5">
     <Col>
     <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
   <Row>
   <Row className='m-0'>
      <Col className='text-center mb-2'>
        <h2 style={{color:"rgb(124, 58, 237)",fontFamily:"sans-serif",color: "rgb(134, 28, 18)"}}>REPORT</h2>
      </Col>
      </Row>
      <Form encType="multipart/form-data">
   <Row className='m-0 '>

    <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>Melava :</b></Form.Label>
      <Form.Select
        aria-label="Default select example"
        value={melava}
        onChange={(e) => setMelava(e.target.value)}
        required
      >
        <option value="">Select Melava</option>
        <option value="1">First Marriage Melava</option>
        <option value="2">Vidhava Vidhoor Melava</option>
        <option value="3">Both Melava</option>
      </Form.Select>
    </Col>

    <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>Gender :</b></Form.Label>
      <Form.Select
        aria-label="Default select example"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        required
      >
        <option value="">Select Gender</option>
        <option value="1">Male</option>
        <option value="2">Female</option>
      </Form.Select>
    </Col>

    <Col sm={12} md={3} className='mt-3'>
      <Form.Label>
       <b>Category :</b> 
      </Form.Label>
      <Form.Select
        aria-label="Default select example"
        onChange={(e)=>getUserData(e.target.value)}

        value={Category}
        required
      >
        <option value="">Choose...</option>
        <option value="1">Doctor/Pharmacy</option>
        <option value="2">Engineering</option>
        <option value="3">Graduates</option>
        <option value="4">Other</option>
        <option value="5">Post Graduates</option>
        <option value="6">Divorcee</option>
        <option value="7">Widow</option>
        <option value="8">Disability</option>
      </Form.Select>
    </Col>
    <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>Total Candidates:</b></Form.Label>
        <Form.Control
        value={TotalNo}
          aria-label="Default select example"
        ></Form.Control>
      </Col>
      <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>From Sr No. :</b></Form.Label>
        <Form.Control
          aria-label="Default select example"
          required
          value={srNo}
          onChange={(e) => setSrNo(e.target.value)}
        ></Form.Control>
      </Col>
   </Row>

   <Row className='m-0 my-2'>
     <Col style={{display:"flex", justifyContent:"center"}}>

             <button className='adminButton py-2 px-3 mx-2 mt-4 rounded' style={{
                backgroundColor: "rgb(233, 224, 227)",
                border: "0px",
                color: "black",
              }} type='submit'  onClick={HandleSaveFun}><b>Show</b></button>
              
              <button className='adminButton py-2 px-3 mx-2 mt-4 rounded text-end' style={{ backgroundColor: "rgb(233, 224, 227)",
                border: "0px",
                color: "black",}} onClick={handlePrint}><b>Print</b></button>

     </Col>
   </Row>
 </Form>
   </Row>
 </div>
     </Col>
   </Row>

   

   {/* ====================== data ==================== */}


   <div ref={componentRef} id="content-to-print" className='pb-5' style={{ width: "100%", height: window.innerHeight }}>
   
   {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

      return (
        <>
        
        <Row className='m-0  mx-5 pb-5' style={{display:"flex",justifyContent:"center",alignContent:"center"}}>
          <div className='row p-0 m-0'  >


              <Row className='text-center'>
                    <Table striped bordered style={{borderColor:"rgb(250, 99, 18)"}}>
                <thead className='text-center' style={{fontSize:"14px",backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )"}}>
          <tr>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उमेदवाराचे नाव</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>शिक्षण </th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>नोकरी/व्यवसाय/शेती</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>जन्मतारीख व वेळ</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उंची</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>देह/वर्ण</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>पोटजात/भाऊ/बहिण</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>अपेक्षा</th>
                    <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}> <span style={{backgroundColor:"white",padding:"5px 15px",borderRadius:"10px"}}>{value.sr_no}</span> </th>
                  </tr>
                </thead>
                <tbody className='text-center' style={{fontSize:"13px"}}>
                {/* {
                      Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

                        return (
                          <> */}
                  <tr>
                    <td className='text-start' style={{  minWidth: '250px' ,borderColor:"rgb(250, 99, 18)"}}><p style={{ fontWeight: "bold",color:"red"}}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </p>
                    <p style={{ fontSize: "14px",marginTop:"-12px"}}>पत्ता : {' '} {value.mr_address}<br />गाव/ शहर : {value.mr_village}{' '}&nbsp;ता : {value.talukamrname} <br />जि : {value.districtmrname}{' '}&nbsp; रा : {value.statemrname}<br />
                    मो :{' '}{value.mobileno}/{value.mobileno2}
                    </p>
                    </td>
                    <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '50px'}}>{value.subeducation_name},&nbsp;<br />{value.other_education}</td>
                    <td style={{borderColor:"rgb(250, 99, 18)", minWidth: '150px'}}>व्यवसाय :{' '}{value.occupation},<br />{value.occupation_detail} <br />
                    वा.प्रा :{' '}{value.amount}<br />
                      { value.farm_g > 0 || value.farm_ac > 0 ? (
                        <>
                          शेती -&nbsp;
                          {value.farm_ac > 0 ? (
                          <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                          ) : null}
                          {value.farm_g > 0 ? (
                            <span >{' '}&nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                            ) : null
                          }
                        </>
                      ) : null}<br />
                      {value.other_property}
                    </td>
                    <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '40px'}}>{value.dob}<br />{value.birth_time}</td>
                    <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '30px'}}>{value.height}</td>
                    <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '70px'}}>{value.complextion}</td>
                    <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '80px'}}>{value.subcastename}<br /><hr />
                    <div>भाऊ :</div> 
                            <span >विवाहित-{value.b_married > 0 ? value.b_married: '-' }</span>
                          
                          
                            <span  className='mx-2'> अविवाहित-{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                        

                        <div>बहीण :</div>

                  <span >विवाहित-{value.s_married > 0 ? value.s_married: '-' }</span>
                  <span   className='mx-2'> अविवाहित-{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                    </td>
                    <td style={{fontSize:"14px" ,borderColor:"rgb(250, 99, 18)"}}>{value.exp_housewife === 1 ? 'गृहिणी ' : null}<br />{value.exp_compatible === 1 ? 'अनुरूप ' : null}<br />{value.exp_farmer === 1 ? 'शेती ' : null}<br />{value.exp_serviceman === 1 ? 'नोकरी ' : null}<br />{value.exp_businessman === 1 ? 'व्यवसाय ' : null}<br />
                    
                    </td>

                    <td><div  style={{ justifyContent: "center", display: "flex" }}>
                      <img className='rounded' src={`nodeApp/image/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "120px", height: "150px", border: "1px solid rgb(250, 99, 18)" }} />
                    </div>
                    </td>
                  </tr>
                  {/* </>
                        )
                      })} */}
                </tbody>
              </Table>
              </Row>

          </div>
        </Row>
                </>
              )
            })}
    </div>
     
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default AdminReports


import React, { useState, useEffect } from 'react'
import './EditProfileCSS.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Form, Row, Col } from 'react-bootstrap';
import men1 from "../../../assets/men1.jfif";
import logo from "../../../assets/Mainlogo.jpg";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminMasterHeader from '../../../CommonComponenets/AdminMasterHeader';
// import {   } from '../../../AllApi/UserApi/UserApi';
import { EducationMaster, adminUpdateProfile,SubeducationMaster, subcastMaster, IncomeMaster, RegsitrationSave, StateMaster, DistrictMaster, TalukaMaster, RegistrationSelect, } from '../../../AllApi/AdminApi/AdminApi';
import { toast } from 'react-toastify';

//===================================multi select=============================================================

import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { style } from '@mui/system';

//==============================================================================================

const EditMainPage = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const select_member = location.state?.member_id;

  //----------Registration Form basic information data save------------//


  const [selectedOptions, setSelectedOptions] = useState([]);

  const [Enfname, setEnfname] = useState()
  const [Enmname, setEnmname] = useState()
  const [Enlname, setEnlname] = useState()
  const [Mrfname, setMrfname] = useState()
  const [Mrmname, setMrmname] = useState()
  const [Mrlname, setMrlname] = useState()
  const [Gender, setGender] = useState()
  const [Maritialsatatus, setMaritialsatatus] = useState()
  const [Complextion, setComplextion] = useState()
  const [Disability, setDisability] = useState()
  const [isMstatusEditing, setisMstatusEditing] = useState(true);
  const [isDstatusEditing, setisDstatusEditing] = useState(true);
  const [melava, setMelava] = useState()
  const [Dateob, setDateob] = useState()
  const [BirthTime, setBirthTime] = useState()
  const [Height, setHeight] = useState()
  const [SubCaste, setSubCaste] = useState()
  const [Education, setEducation] = useState()
  const [OtherQualification, setOtherQualification] = useState()
  const [EduCategory, setEduCategory] = useState([])
  const [SubeducationData, setSubeducationData] = useState([]);
  const [EduType, setEduType] = useState()
  const [OccupationDetail, setOccupationDetail] = useState()
  const [AnnualIncome, setAnnualIncome] = useState()
  const [FaFNameEn, setFaFNameEn] = useState()
  const [FaMNameEn, setFaMNameEn] = useState()
  const [FaLNameEn, setFaLNameEn] = useState()
  const [FaFNameMr, setFaFNameMr] = useState()
  const [FaMNameMr, setFaMNameMr] = useState()
  const [FaLNameMr, setFaLNameMr] = useState()
  const [FarmAcar, setFarmAcar] = useState()
  const [FarmGuntha, setFarmGuntha] = useState()
  const [OtherProperty, setOtherProperty] = useState()
  const [othereducation, setOthereducation] = useState(null);
  const [MarriedBro, setMarriedBro] = useState()
  const [UnMarriedBro, setUnMarriedBro] = useState()
  const [MarriedSis, setMarriedSis] = useState()
  const [UnMarriedSis, setUnMarriedSis] = useState()
  const [Compatible, setCompatible] = useState(0);
  const [Businessman, setBusinessman] = useState(0);
  const [Serviceman, setServiceman] = useState(0);
  const [Farmer, setFarmer] = useState(0);
  const [HouseWife, setHouseWife] = useState(0);
  const [AddressEn, setAddressEn] = useState()
  const [CityEn, setCityEn] = useState()
  const [AddressMr, setAddressMr] = useState()
  const [CityMr, setCityMr] = useState()
  const [State, setState] = useState()
  const [District, setDistrict] = useState()
  const [Taluka, setTaluka] = useState()
  const [Email, setEmail] = useState()
  const [Mobno1, setMobno1] = useState()
  const [Mobno2, setMobno2] = useState()
  const [Regid,setRegid]=useState() 
  const [Member_id,setMember_id]=useState()
  const [image, setImage] = useState('');
  const [updateImage, setUpdateImage] = useState('');

  //----------------------updateData--------------------------------
   //==================img code=====================
   const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUpdateImage(file);
  };
  


  const updatedata = (event) => {

    event.preventDefault()
    const idArray = EduCategory.map((item) => item.id);
    const newEduCategory = idArray.join(", ");   

    const regdata = {
      reg_id:Regid,
      gender: Gender,
      marital_status: Maritialsatatus,
      disability: Disability,
      melava_type: melava,
      complextion: Complextion,
      en_fname: Enfname,
      en_mname: Enmname,
      en_lname: Enlname,
      mr_fname: Mrfname,
      mr_mname: Mrmname,
      mr_lname: Mrlname,
      dob: Dateob,
      birth_time: BirthTime,
      height: Height,
      subcaste_id: SubCaste,
      edu_id: Education,
      subedu_id: newEduCategory,
      occupation: EduType,
      occupation_detail: OccupationDetail,
      income_id: AnnualIncome,
      en_address: AddressEn,
      mr_address: AddressMr,
      en_village: CityEn,
      mr_village: CityMr,
      taluka_id: Taluka,
      district_id: District,
      state_id: State,
      email_id: Email,
      mobileno: Mobno1,
      mobileno2: Mobno2,
      en_fatherfname: FaFNameEn,
      en_fathermname: FaMNameEn,
      en_fatherlname: FaLNameEn,
      mr_fatherfname: FaFNameMr,
      mr_fathermname: FaMNameMr,
      mr_fatherlname: FaLNameMr,
      farm_ac: FarmAcar,
      farm_g: FarmGuntha,
      other_property: OtherProperty,
      other_education: othereducation,
      b_married: MarriedBro,
      b_unmarried: UnMarriedBro,
      s_married: MarriedSis,
      s_unmarried: UnMarriedSis,
      exp_compatible: Compatible,
      exp_businessman: Businessman,
      exp_serviceman: Serviceman,
      exp_farmer: Farmer,
      exp_housewife: HouseWife,
      member_id:Member_id,

    }

    const formData = new FormData();
  formData.append("file", updateImage);


  for (const key in regdata) {
    formData.append(key, regdata[key]);
  }



    // axios.put("http://192.168.29.112:9081/api/member/update/adminMemberProfile")
    axios.put(adminUpdateProfile, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  
    .then((res) => {
        console.log("-+++-++++++-+-+--",res);

      if (res.data.message === "UPDATE") {
        toast.success('Profile UPDATED successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        navigate('/admin/member/mp')
      } 
      else if(res.data.message === "NOTFOUND") {
        toast.warn('NOTFOUND!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        navigate('/admin/member/mp')
      }
    })
    .catch((err) => {
      toast.error('Failed To Update, try again!!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    });

  }
  const [update, setUpdate] = useState()

  const convertToHHmm = (timeStr) => {
    const date = new Date(`2000-01-01T${timeStr}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedTime;
  };

  const getdata = (SubeducationData) => {
    axios
    // axios.get("http://192.168.29.112:9081/api/registration/select")
      .get(`${RegistrationSelect}${select_member}`)
      .then((res) => {
        console.log("---------getdata***------------",res.data)
        if (res.data.json !== null) {
          const api = res.data;
          const getSubeducationNameById = (id) => {
       
            const matchingOption = SubeducationData.find((option) => option.id === id);
            
            return matchingOption && matchingOption.subeducation_name ? matchingOption.subeducation_name : "Not Found";

         };

          api.map((api, index) => {
            setImage(api.image);
            setRegid(api.reg_id)
            setGender(api.gender)
            setMaritialsatatus(api.marital_status)
            setDisability(api.disability)
            setMelava(api.melava_type)
            setComplextion(api.complextion)
            setEnfname(api.en_fname)
            setEnmname(api.en_mname)
            setEnlname(api.en_lname)
            setMrfname(api.mr_fname)
            setMrmname(api.mr_mname)
            setMrlname(api.mr_lname)
            
            // setDateob(api.dob)
            const [day, month, year] = api.dob.split('/');
            const convertedDate = `${year}-${month}-${day}`;
            setDateob(convertedDate)
            
            // setBirthTime(api.birth_time)
            const timeParts = api.birth_time.split(" ");
            if (timeParts.length === 2) {
              const [time, ampm] = timeParts;
              const [hoursStr, minutesStr] = time.split(":");
              let hours = parseInt(hoursStr, 10);
              if (ampm === "PM" && hours !== 12) {
                hours += 12;
              } else if (ampm === "AM" && hours === 12) {
                hours = 0;
              }

              const formattedTime = `${hours
                .toString()
                .padStart(2, "0")}:${minutesStr}`;
              
              setBirthTime(formattedTime);
            }

            setHeight(api.height)
            setSubCaste(api.subcaste_id)
            setEducation(api.edu_id);
            const subidarray = api.subedu_id.match(/[^,]+/g);
            const subid = subidarray.map((value) => (
             
              {
             
              id: parseInt(value),
              subeducation_name: getSubeducationNameById(parseInt(value)),
            })
            );

            setEduCategory(subid);
            setEduType(api.occupation)
            setOccupationDetail(api.occupation_detail)
            setAnnualIncome(api.income_id)
            setAddressEn(api.en_address)
            setAddressMr(api.mr_address)
            setCityEn(api.en_village)
            setCityMr(api.mr_village)
            setTaluka(api.taluka_id)
            setDistrict(api.district_id)
            setState(api.state_id)
            setEmail(api.email_id)
            setMobno1(api.mobileno)
            setMobno2(api.mobileno2)
            setFaFNameEn(api.en_fatherfname)
            setFaMNameEn(api.en_fathermname)
            setFaLNameEn(api.en_fatherlname)
            setFaFNameMr(api.mr_fatherfname)
            setFaMNameMr(api.mr_fathermname)
            setFaLNameMr(api.mr_fatherlname)
            setFarmAcar(api.farm_ac)
            setFarmGuntha(api.farm_g)
            setOtherProperty(api.other_property)
            setOthereducation(api.other_education);
            setMarriedBro(api.b_married)
            setUnMarriedBro(api.b_unmarried)
            setMarriedSis(api.s_married)
            setUnMarriedSis(api.s_unmarried)
            setMember_id(api.member_id)


            setHouseWife(api.exp_houseWife);
            setCompatible(api.exp_compatible);
            setBusinessman(api.exp_businessman);
            setServiceman(api.exp_serviceman);
            setFarmer(api.exp_farmer);
            let expdata=[]
            
            if(api.exp_houseWife == 1){
              expdata.push("HouseWife")
            }
            if(api.exp_compatible == 1){
              expdata.push("Compatible")
            }
            if(api.exp_businessman == 1){
              expdata.push("Businessman")
            }
            if(api.exp_serviceman == 1){
              expdata.push("Serviceman")
            }
            if(api.exp_farmer == 1){
              expdata.push("Farmer")
            }

            if(expdata.length >0){
              setSelectedOptions(expdata)
            }
            
          })

          setUpdate(true)
        }
      })
      .catch((error) => {
      });
  };


  useEffect(() => {
    getdata()

  }, [])


  useEffect(() => {
      getdata(SubeducationData);
  }, [SubeducationData]);

  //master use state and other code
  const [EducationData, setEducationData] = useState([])
  const [SubCastData, setSubCastData] = useState([])
  const [IncomeData, setIncomeData] = useState([])
  const [StateData, setStateData] = useState([])
  const [DistictData, setDistictData] = useState([])
  const [TalukaData, setTalukaData] = useState([])

  const status = JSON.parse(sessionStorage.getItem("status"));


  const getEducationData = () => {
    // axios.get("http://192.168.29.112:9081/api/education/select")
    axios.get(EducationMaster)
      .then((res) => {
        if (res.data !== null) {
          setEducationData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

    const getSubeducationData = () => {
      // axios.get("http://192.168.29.112:9081/api/subeducation/select")
      axios.get(`${SubeducationMaster}${Education}`) 
        .then((res) => {
          if (res.data !== null) {
            setSubeducationData(res.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

  const getSubCastData = () => {
    // axios.get("http://192.168.29.112:9081/api/subcast/select")
    axios.get(subcastMaster)
      .then((res) => {
        if (res.data !== null) {
          setSubCastData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getIncomeData = () => {
    // axios.get("http://192.168.29.112:9081/api/income/select")
    axios.get(IncomeMaster)
      .then((res) => {
        if (res.data !== null) {
          setIncomeData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getStateData = () => {
    // axios.get("http://192.168.29.112:9081/api/state/getState")
    axios.get(StateMaster)
      .then((res) => {
        if (res.data !== null) {
          setStateData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDistrictData = () => {
// axios.get("http://192.168.29.112:9081/api/district/getDistrict")
    axios.get(`${DistrictMaster}${State}`)

      .then((res) => {
        if (res.data !== null) {
          setDistictData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTalukaData = () => {
   // axios.get("http://192.168.29.112:9081/api/taluka/getTaluka")
    axios.get(`${TalukaMaster}${State}/${District}`)

      .then((res) => {
        if (res.data !== null) {
          setTalukaData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    getEducationData();
    getSubeducationData();
    getSubCastData();
    getIncomeData();
    getStateData();

   }, [Education])
  useEffect(() => {
    getDistrictData()
    getTalukaData()

    }, [State, District])

    //================checkbox====================
  
    const handleCheckboxChange = (event, option) => {
      if (event.target.checked) {
        if (selectedOptions.length < 5) {
          setSelectedOptions([...selectedOptions, option]);
        } else {
          toast.error('You can select only two options!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          event.target.checked = false;
        }

      } else {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      }
    
      if (option === 'Farmer') {
        setFarmer(event.target.checked ? 1 : 0);
      }
      if (option === 'Businessman') {
        setBusinessman(event.target.checked ? 1 : 0);
      }
      if (option === 'Compatible') {
        setCompatible(event.target.checked ? 1 : 0);
      }
      if (option === 'HouseWife') {
        setHouseWife(event.target.checked ? 1 : 0);
      }
      if (option === 'Serviceman') {
        setServiceman(event.target.checked ? 1 : 0);
      }
  
      
    };

//-----------------------------------------------------------------

  const handleClose = () => {
   navigate("/admin/member/mp")
  };
  
//--------------------------------------CSS--------------------
  const info = {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    backgroundColor: "#b3b3d6",
    color: "black",
  }
  const footerStyle = {
    backgroundColor: "#462f46",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding: "8px",
    textAlign: "center",
    color: "#fff",
    fontSize: "12px",
    bottom: 0,
    width: "100%",
    letterSpacing: "1px",
  }

 
  //------------------------------------------------------------------------------------------------------------
  const maritalStatusOptions = [
    { value: '', label: 'Select Marital Status' },
    { value: '1', label: 'Unmarried' },
    { value: '2', label: 'Divorcee' },
    { value: '3', label: 'Widow' },
  ];

  const melavaOptions = [
    { value: '', label: 'Choose..', hidden: false },
    { value: '1', label: 'Single Melava', hidden: false },
    { value: '2', label: 'Vidhava Vidhoor Melava', hidden: false },
    { value: '3', label: 'Both Melava', hidden: false },
  ];

  // Event handler for Marital Status change
  const handleMaritalStatusChange = (e) => {
    setMaritialsatatus(e.target.value);
  };
  //=================================multiple select==========================================

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setEduCategory(selectedOptions);
  };

 
//============================================================================================================
  return (
    <>
    
    <div className="p-0">
        {' '}
        <AdminMasterHeader />{' '}
      </div>

        <div className="editProfileTitle col-12 py-1 ps-3 pe-3" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                 <h4 style={{ fontWeight: '550',}}>Edit Member Profile</h4>
          
                  <button className='rounded'  onClick={handleClose}  style={{ padding: "5px 10px", backgroundColor: "orange", border: "0px",fontWeight:"600", color: "black", fontSize: "15px",width:"80px", }}>Back</button>

        </div>
        <form onSubmit={(event) => updatedata(event)}>
        
           <div className="row profile-details1 m-0">
      

                <div className='col-12 col-md-3 pt-3'> 
                    <div className="row">
                    <div className="col-2 col-md-0"></div>
                    <div className="col-8 col-md-12">
                    <img 
                      style={{border:"1px solid grey"}}
                          src={`nodeApp/uploads/${image}`}
                          alt={`${Enfname}'s Photo`}
                            className="small-photo mb-2" />
                    </div>
                    <div className="col-2 col-md-0"></div>
                    </div>

                            <div className='col-12 d-flex'>
                              <div className='col-4'><p>Change Image :</p></div>
                              <div className='col-8'>
                              <input className='col-12' type="file" accept=".jpg,.jpeg,.png"  onChange={(e) => handleImageChange(e)} />
                              </div>
                            </div>
 
                  </div>
                 
              

                <div className='col-12 col-md-9 mt-3'>
                
                  {/* Basic Information */}
                  <div className='row m-0 border mb-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px   grey" }}>
                
                      <div className='col-12 border-bottom mb-4' style={info}><h6>Basic Information/मुलभूत माहिती</h6></div>

                      <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                        <Form.Group as={Col}>
                          <Form.Label> <p>First Name (English):</p></Form.Label>
                          <Form.Control type="text" style={{marginTop:"-20px"}}  value={Enfname} onChange={(e) => setEnfname(e.target.value)} required />
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label> <p>Middle Name(English):</p></Form.Label>
                        <Form.Control  value={Enmname} style={{marginTop:"-20px"}} onChange={(e) => setEnmname(e.target.value)} required  />
                        </Form.Group>

                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                      <Form.Label> <p>Last Name (English):</p></Form.Label>
                        <Form.Control  value={Enlname} style={{marginTop:"-20px"}} onChange={(e) => setEnlname(e.target.value)} required />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="col-12 d-flex mt-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>

                    <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                    <Form.Label><p>पहिले नाव (मराठी):</p></Form.Label>
                        <Form.Control  value={Mrfname} style={{marginTop:"-20px"}} onChange={(e) => setMrfname(e.target.value)} required  />
                        </Form.Group>
                      </Col>
                    <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                    <Form.Label> <p>मधले नाव (मराठी):</p></Form.Label>
                        <Form.Control value={Mrmname} style={{marginTop:"-20px"}} onChange={(e) => setMrmname(e.target.value)} required />
                        </Form.Group>
                      </Col>
                    <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                    <Form.Label> <p>आडनाव (मराठी):</p></Form.Label>
                        <Form.Control  value={Mrlname} style={{marginTop:"-20px"}} onChange={(e) => setMrlname(e.target.value)} required />
                        </Form.Group>
                      </Col>

                    </Row>

                    <Row className=' col-12 d-flex p-2 mt-1' style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                  

                    <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                      <Form.Label> <p>Gender/लिंग:</p></Form.Label>
                      <Form.Select value={Gender} style={{marginTop:"-20px"}} onChange={(e) => setGender(e.target.value)} required>
                        <option value="">Select Gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                      <Form.Label> <p>Marital Status/वैवाहिक स्थिती:</p></Form.Label>
                      <Form.Select value={Maritialsatatus} style={{marginTop:"-20px"}} onChange={(e) => setMaritialsatatus(e.target.value)} required disabled={isMstatusEditing ? false : true}>
                        <option value="">Choose...</option>
                        {maritalStatusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                      <Col md={4} className='d-flex'>
                        <Form.Group as={Col}>
                          <Form.Label> <p>Complextion/रंग:</p></Form.Label>
                          <Form.Select value={Complextion} style={{marginTop:"-20px"}} onChange={(e) => setComplextion(e.target.value)} required>
                            <option>Choose...</option>
                            <option value="गोरा">Fair - गोरा</option>
                            <option value="गहू">Brown - गहू</option>
                            <option value="सावळा">Dark - सावळा</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>


                      </Row>

                      <Row className=' col-12 d-flex p-2 mt-1' style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                        <Form.Group as={Col}>
                          <Form.Label> <p>Disability/दिव्यांग:</p></Form.Label>
                          <Form.Select value={Disability} style={{marginTop:"-20px"}}
                                                  onChange={(e) => setDisability(e.target.value)}
                                                  required>
                            <option value="1">No</option>
                            <option value="2">Yes</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                   
                      <Col md={4} className='d-flex'>
                          <Form.Group as={Col}>
                            <Form.Label> <p>Melava/मेळावा:</p></Form.Label>
                            <Form.Select
                              value={melava} style={{marginTop:"-20px"}}
                              onChange={(e) => setMelava(e.target.value)}
                              required
                              disabled={isDstatusEditing ? false : true}
                            >
                              {melavaOptions
                                .filter((option) => {
                                  if (Maritialsatatus === '2' || Maritialsatatus === '3') {
                                    return option.value !== '1' && option.value !== '3';
                                  }
                                  return true;
                                })
                                .map((option) => (
                                  <option key={option.value} value={option.value} hidden={option.hidden}>
                                    {option.label}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col md={4} className='d-flex'>
                          <Form.Group as={Col} htmlFor="dateInput">
                            <Form.Label> <p>Date of birth/जन्मतारीख:</p></Form.Label>
                            <Form.Control
                              type='date' style={{marginTop:"-20px"}}
                              value={Dateob}
                              onChange={(e) => setDateob(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>

                      </Row>

                      <Row className=' col-12 d-flex p-2 mb-3' style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                        <Col md={4} className='d-flex'>
                          <Form.Group as={Col}>
                            <Form.Label> <p>Birth time/जन्मवेळ:</p></Form.Label>
                            <Form.Control
                              type='time' style={{marginTop:"-20px"}}
                              value={BirthTime}
                              onChange={(e) => setBirthTime(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>


                        <Col md={4} className='d-flex'>
                          <Form.Group as={Col}>
                            <Form.Label> <p>Height/उंची:</p></Form.Label>
                            <Form.Control
                              value={Height} style={{marginTop:"-20px"}}
                              onChange={(e) => setHeight(e.target.value)}
                              data-validetta="required"
                              required
                              as="select"
                            >
                              <option value="">Choose...</option>
                                                  <option value="Below 4 ft">Below 4ft
                                                  </option>
                                                  <option value="4 ft &nbsp; 06 in">4ft 06in
                                                  </option>
                                                  <option value="4 ft &nbsp; 07 in">4ft 07in
                                                  </option>
                                                  <option value="4 ft &nbsp; 08 in">4ft 08in
                                                  </option>
                                                  <option value="4 ft &nbsp; 09 in">4ft 09in
                                                  </option>
                                                  <option value="4 ft &nbsp; 10 in">4ft 10in
                                                  </option>
                                                  <option value="4 ft &nbsp; 11 in">4ft 11in
                                                  </option>
                                                  <option value="5 ft">5ft
                                                  </option>
                                                  <option value="5 ft &nbsp; 01 in">5ft 01in
                                                  </option>
                                                  <option value="5 ft &nbsp; 02 in">5ft 02in
                                                  </option>
                                                  <option value="5 ft &nbsp; 03 in">5ft 03in
                                                  </option>
                                                  <option value="5 ft &nbsp; 04 in">5ft 04in
                                                  </option>
                                                  <option value="5 ft &nbsp; 05 in">5ft 05in
                                                  </option>
                                                  <option value="5 ft &nbsp; 06 in">5ft 06in
                                                  </option>
                                                  <option value="5 ft &nbsp; 07 in">5ft 07in
                                                  </option>
                                                  <option value="5 ft &nbsp; 08 in">5ft 08in
                                                  </option>
                                                  <option value="5 ft &nbsp; 09 in">5ft 09in
                                                  </option>
                                                  <option value="5 ft &nbsp; 10 in">5ft 10in
                                                  </option>
                                                  <option value="5 ft &nbsp; 11 in">5ft 11in
                                                  </option>
                                                  <option value="6 ft">6ft
                                                  </option>
                                                  <option value="6 ft &nbsp; 01 in">6ft 01in
                                                  </option>
                                                  <option value="6 ft &nbsp; 02 in">6ft 02in
                                                  </option>
                                                  <option value="6 ft &nbsp; 03 in">6ft 03in
                                                  </option>
                                                  <option value="6 ft &nbsp; 04 in">6ft 04in
                                                  </option>
                                                  <option value="6 ft &nbsp; 05 in">6ft 05in
                                                  </option>
                                                  <option value="6 ft &nbsp; 06 in">6ft 06in
                                                  </option>
                                                  <option value="6 ft &nbsp; 07 in">6ft 07in
                                                  </option>
                                                  <option value="6 ft &nbsp; 08 in">6ft 08in
                                                  </option>
                                                  <option value="6 ft &nbsp; 09 in">6ft 09in
                                                  </option>
                                                  <option value="6 ft &nbsp; 10 in">6ft 10in
                                                  </option>
                                                  <option value="6 ft &nbsp; 11 in">6ft 11in
                                                  </option>
                                                  <option value="7 ft">7ft
                                                  </option>
                                                  <option value="Above 7 ft">Above 7ft
                                                  </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md={4} className='d-flex'>
                          <Form.Group as={Col}>
                            <Form.Label> <p>Subcast/पोटजाती:</p></Form.Label>
                            <Form.Control
                              value={SubCaste} style={{marginTop:"-20px"}}
                              onChange={(e) => setSubCaste(e.target.value)}
                              as="select"
                              required
                            >
                              <option value="">Choose...</option>
                              {SubCastData.map((value, index) => (
                                <option key={index.id} value={value.id}>
                                  {value.subcastename}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                      </Row>
                   
                  </div>

                  {/* Education Information */}
                  <div className='row m-0 border mt-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6></div>
                    <Row className=' col-12 d-flex p-2' style={{flexWrap:"wrap",justifyContent:"space-between"}}>

                    <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label style={{ lineHeight: "18px" }}> <p>Education/शिक्षण:</p> </Form.Label>
                        <Form.Control
                          value={Education}
                          filterSelectedOptions
                          onChange={(e) => setEducation(e.target.value)}
                          required
                          as="select" style={{marginTop:"-20px"}}
                        >
                          <option value="">Choose...</option>
                          {EducationData.map((value, index) => (
                            <option key={index.id} value={value.id}>
                              {value.education_name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                      
                    <Col md={6} className='d-flex'>
                    <Form.Group as={Col}>
                      <Form.Label ><p>Education Category/शिक्षण श्रेणी:</p></Form.Label>
                      <Autocomplete style={{marginTop:"-20px"}}
                        multiple
                        id="education-category"
                        value={EduCategory}
                        onChange={(event, newValue) => {
                          setEduCategory(newValue);
                          if (newValue.length > 2) {
                            // Display a warning message using your preferred toast library
                            // For demonstration, a console.log message is used here
                            console.warn('Please, select only two options!');
                          }
                        }}
                        options={SubeducationData}
                        getOptionLabel={(option) => option.subeducation_name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              style={{ color: "black" }}
                              label={option.subeducation_name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Education" style={{ color: "black", marginBottom: "10px" }} />
                        )}
                      />
                    </Form.Group>
                  </Col>
                      
                    </Row>

                    <Row className=' col-12 d-flex p-2 mb-2' style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                   
                    <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Occupation Type/ व्यवसायाचा प्रकार:</p></Form.Label>
                        <Form.Select style={{marginTop:"-20px"}}
                          aria-label="Default select example"
                          value={EduType}
                          onChange={(e) => setEduType(e.target.value)}
                          required
                        >
                          <option value="">Choose...</option>
                          <option>Business</option>
                          <option>Government Job</option>
                          <option>Private Job</option>
                          <option>Professional</option>
                          <option>Agricultural</option>
                          <option>Studying</option>
                          <option>Not Working</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Other Qualification(इतर पात्रता):</p></Form.Label>
                        <Form.Control
                          type='text' style={{marginTop:"-20px"}}
                          value={othereducation}
                          onChange={(e) => setOthereducation(e.target.value)}
                        />
                      </Form.Group>
                    </Col>

                    </Row>
                    
                    <Row className='col-12 mb-3 d-flex' style={{flexWrap:"wrap",justifyContent:"space-between"}}>

                    <Col md={6} className='d-flex'>
                    <Form.Group as={Col}>
                      <Form.Label><p>Annual Income/वार्षिक उत्पन्न:</p></Form.Label>
                      <Form.Control
                        as='select' style={{marginTop:"-20px"}}
                        aria-label="Default select example"
                        value={AnnualIncome}
                        onChange={(e) => setAnnualIncome(e.target.value)}
                        required
                      >
                        <option value="">Choose...</option>
                        {IncomeData.map((value, index) => (
                          <option key={index} value={value.id}>
                            {value.amount}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                    <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                      <Form.Label>
                          <p style={{ lineHeight: "18px",}}>Occupation & Addrress/व्यवसाय आणि पत्ता:</p>
                        </Form.Label>
                        <Form.Control style={{marginTop:"-17px"}}
                          value={OccupationDetail}
                          onChange={(e) => setOccupationDetail(e.target.value)}
                          required
                        />
                      </Form.Group>
                      </Col>

                    </Row>
                   
                  </div>

                  {/* Family Information */}
                  <div className='row m-0 border mt-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Family Information/कौटुंबिक माहिती</h6></div>

                    <Row className='col-12 d-flex mb-2' style={{justifyContent:"space-between"}}>
                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Father First Name(English):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={Enmname}
                            onChange={(e) => setFaFNameEn(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Father Middle Name(English):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={FaMNameEn}
                            onChange={(e) => setFaMNameEn(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Father Last Name(English):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={Enlname}
                            onChange={(e) => setFaLNameEn(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Row>

                      <Row className='col-12 d-flex mb-2' style={{justifyContent:"space-between"}}>
                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>वडिलांचे नाव(मराठी):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={FaFNameMr}
                            onChange={(e) => setFaFNameMr(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>वडिलांचे मधले नाव(मराठी):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={FaMNameMr}
                            onChange={(e) => setFaMNameMr(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>वडिलांचे आडनाव(मराठी):</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={FaLNameMr}
                            onChange={(e) => setFaLNameMr(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Row>

                      {/* Farm Details Inputs */}
                      <Row className='col-12 d-flex mb-2' style={{justifyContent:"space-between"}}>
                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Farm in Acar/शेत जमीन एकर:</p>
                          </Form.Label>
                          <Form.Control
                            as="select" style={{marginTop:"-20px"}}
                            value={FarmAcar}
                            onChange={(e) => setFarmAcar(e.target.value)}
                            required
                          >
                            <option value="">Choose...</option>
                            {/* Add options dynamically */}
                            {Array.from({ length: 51 }, (_, i) => i).map((value) => (
                              <option key={value}>{value}</option>
                            ))}
                            <option>Above 50...</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Farm in Guntha/शेत जमीन गुंठे:</p>
                          </Form.Label>
                          <Form.Control
                            as="select" style={{marginTop:"-20px"}}
                            value={FarmGuntha}
                            onChange={(e) => setFarmGuntha(e.target.value)}
                            required
                          >
                            <option value="">Choose...</option>
                            {/* Add options dynamically */}
                            {Array.from({ length: 51 }, (_, i) => i).map((value) => (
                              <option key={value}>{value}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md={4} className='mb-2'>
                          <Form.Label className='para'>
                            <p>Other Property/इतर मालमत्ता:</p>
                          </Form.Label>
                          <Form.Control
                            type="text" style={{marginTop:"-20px"}}
                            value={OtherProperty}
                            onChange={(e) => setOtherProperty(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Row>

                      <Row className='col-12 d-flex p-2 mb-2' style={{ flexWrap: "wrap" }}>
                          {/* Brother Details */}
                          <Row className='col-12 col-md-6 d-flex'>
                            <Form.Group as={Col} md={2}>
                              <Form.Label className='para'>
                                <p>Brother/भाऊ:</p>
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label className='para'>
                                <p>Unmarried/अविवाहित</p>
                              </Form.Label>
                              <Form.Control
                                type='number'
                                value={UnMarriedBro}
                                onChange={(e) => setUnMarriedBro(e.target.value)}
                                required
                              />
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label className='para'>
                                <p>Married/विवाहित</p>
                              </Form.Label>
                              <Form.Control
                                type='number'
                                value={MarriedBro}
                                onChange={(e) => setMarriedBro(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Row>

                          {/* Sister Details */}
                          <Row className='col-12 col-md-6 d-flex'>
                            <Form.Group as={Col} md={2}>
                              <Form.Label className='para'>
                                <p>Sister/बहीण:</p>
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label className='para'>
                                <p>Unmarried/अविवाहित</p>
                              </Form.Label>
                              <Form.Control
                                type='number'
                                value={UnMarriedSis}
                                onChange={(e) => setUnMarriedSis(e.target.value)}
                                required
                              />
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label className='para'>
                                <p>Married/विवाहित</p>
                              </Form.Label>
                              <Form.Control
                                type='number'
                                value={MarriedSis}
                                onChange={(e) => setMarriedSis(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Row>
                        </Row>

                  </div>

                  {/* Contact Information */}
                  <div className='row m-0 border mt-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}> 
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Contact Information/संपर्क माहिती</h6></div>

                    <Row className='col-12 p-2 d-flex' style={{ flexWrap: "wrap" }}>
                        {/* State */}
                        <Col md={4} className='col-12 d-flex'>
                          <Form.Group as={Col}>
                          <Form.Label className='para'>
                              <p>State(राज्य):</p>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              aria-label="Default select example"
                              value={State}
                              onChange={(e) => setState(e.target.value)}
                              required
                            >
                              <option value="">Choose option</option>
                              {StateData.map((value, index) => (
                                <option key={index} value={value.id}>
                                  {`${value.stateenname}:${value.statemrname}`}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* District */}
                        <Col  md={4} className='col-12 d-flex'>
                          <Form.Group as={Col}>
                          <Form.Label className='para'>
                              <p>District(जिल्हा):</p>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              aria-label="Default select example"
                              value={District}
                              onChange={(e) => setDistrict(e.target.value)}
                              required
                            >
                              <option value="">Choose option</option>
                              {DistictData.map((value, index) => (
                                <option key={index} value={value.id}>
                                  {`${value.districtenname}:${value.districtmrname}`}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        {/* Taluka */}
                        <Col md={4} className='col-12 d-flex'>
                          <Form.Group as={Col}>
                          <Form.Label className='para'>
                              <p>Taluka(तालुका):</p>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              aria-label="Default select example"
                              value={Taluka}
                              onChange={(e) => setTaluka(e.target.value)}
                              required
                            >
                              <option value="">Choose option</option>
                              {TalukaData.map((value, index) => (
                                <option key={index} value={value.id}>
                                  {`${value.talukaenname}:${value.talukamrname}`}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                    <Row className='col-12 p-2 d-flex' style={{ flexWrap: "wrap",justifyContent:"space-between" }}>
                      {/* Address (English) */}
                      <Col md={6} className='col-12 d-flex mb-3'>
                        <Form.Group as={Col} >
                        <Form.Label className='para'>
                            <p>Address(English):</p>
                          </Form.Label>
                          <Form.Control
                            style={{marginTop:"-20px"}}
                            value={AddressEn}
                            onChange={(e) => setAddressEn(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>

                      {/* Village/City (English) */}
                      <Col md={6} className='col-12 d-flex mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p>Village/City(English):</p>
                          </Form.Label>
                          <Form.Control style={{marginTop:"-20px"}}
                            value={CityEn}
                            onChange={(e) => setCityEn(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>

                      {/* Address (Marathi) */}
                      <Col md={6} className='col-12 d-flex mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p>पत्ता(मराठी):</p>
                          </Form.Label>
                          <Form.Control
                            style={{marginTop:"-20px"}}
                            value={AddressMr}
                            onChange={(e) => setAddressMr(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>

                      {/* Village/City (Marathi) */}
                      <Col md={6} className='col-12 d-flex mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p>गाव/शहर(मराठी):</p>
                          </Form.Label>
                          <Form.Control style={{marginTop:"-20px"}}
                            value={CityMr}
                            onChange={(e) => setCityMr(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>


                    <Row className='col-12 d-flex p-2' style={{ flexWrap: "wrap" }}>
                      {/* Email */}
                      <Col md={4} className='col-12 d-flex mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p style={{ lineHeight: "18px" }}>Email/ईमेल:</p>
                          </Form.Label>
                          <Form.Control
                            type="email" style={{marginTop:"-20px"}}
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>

                      {/* Mobile No-1 */}
                      <Col md={4} className='col-12 d-flex  mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p style={{ lineHeight: "18px" }}>Mobile No-1/मोबाईल नंबर-1:</p>
                          </Form.Label>
                          <Form.Control
                            type="tel" style={{marginTop:"-20px"}}
                            pattern="[0-9]{10}"
                            maxLength="10"
                            value={Mobno1}
                            onChange={(e) => setMobno1(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>

                      {/* Mobile No-2 */}
                      <Col md={4} className='col-12 d-flex  mb-3'>
                        <Form.Group as={Col}>
                        <Form.Label className='para'>
                            <p style={{ lineHeight: "18px" }}>Mobile No-2/मोबाईल नंबर-2:</p>
                          </Form.Label>
                          <Form.Control
                            type="tel" style={{marginTop:"-20px"}}
                            pattern="[0-9]{10}"
                            maxLength="10"
                            value={Mobno2}
                            onChange={(e) => setMobno2(e.target.value)}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                  </div>

                  {/* Expectations about spouse */}

                  <Row  className='row m-0 border mt-3' style={{ backgroundColor: "#fff",boxShadow:"1px 1px 5px grey" }}>
                  <div className='col-12 border-bottom mb-3' style={info}><h6>Expectations about spouse -/जोडीदाराबाबत अपेक्षा</h6></div>
                  { Gender === 1 ? (
                  <Col sm={12} md={3}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                  
                  <input
                      type='checkbox'
                      name='HouseWife'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'HouseWife')
                      }  
                      defaultChecked={selectedOptions}
                     checked={selectedOptions.includes('HouseWife')}
                    />
                    <h6 className='ms-2 pt-2'>HouseWife/ गृहिणी</h6>
                  </label>
                </Col>
              ) : null}

                  <Col sm={12} md={3}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                        <input
                      type='checkbox'
                      name='compatible'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'Compatible')
                      }
                      defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Compatible')}
                    />
                    <h6 className='ms-2 pt-2'>Compatible/अनुरूप</h6>
                  </label>
                </Col>
                  <Col sm={12} md={2}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        type='checkbox'
                        name='businessman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Businessman')
                        }
                        defaultChecked={selectedOptions}
                        checked={selectedOptions.includes('Businessman')}
                      />
                      <h6 className='ms-2 pt-2'>Business/व्यवसाय</h6>
                    </label>
                  </Col>
                  <Col sm={12} md={2}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      type='checkbox'
                      name='serviceman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Serviceman')
                        }
                        defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Serviceman')}
                    />
                    <h6 className='ms-2 pt-2'>Service/नोकरी</h6>
                  </label>
                </Col>
                <Col sm={12} md={2}>
                  <label
                    className='pt-1 pb-md-3'
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='farmer'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Farmer')
                    }
                    checked={selectedOptions.includes('Farmer')}
                  />
                  <h6 className='ms-2 pt-2'>Farmer/शेतकरी</h6>
                </label>
              </Col>
                 </Row>


                    {/* Buttons */}
                  <div className='row p-0 d-flex m-0 mb-md-3' style={{justifyContent:"center"}}>
                    <div>
                        <div className='mt-4' style={{ display: "flex", justifyContent: "end", }}>
                                <button  type='submit' className='rounded' style={{ padding: "7px 15px", backgroundColor: "orange", color: "black",border:"none",fontWeight:"600", marginBottom: "30px", fontSize: "16px" }} >Update Data</button>
                             
                        </div>
                    </div>
                   
                </div>
                 
                </div>
               
               {/* <div className="col-12 col-md-1 pt-3"></div> */}


                <div style={footerStyle}>
                  &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
                </div>
              </div>

            </form>

    </>
  )
}

export default EditMainPage
import React, { useEffect } from 'react';
import logonew from '../assets/logonew.jpg';
import {useNavigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AdminMasterFooter from './AdminMasterFooter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo1 from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';

const AdminMasterHeader = () => {

    
  const navigate=useNavigate()

  const checkLogin = () => {
    const getSessionval = sessionStorage.getItem('userdata')
    if (!getSessionval) {
      navigate('/')
    }
   
  }

  useEffect(() => {
    checkLogin()
  }, [])


  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/admin");    
  }
  const footerStyle = {
    backgroundColor:"#660505",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
  
      
  return (
    <>

        {/* <div className='' > */}
        <Container fluid className='p-0 m-0'>
      <Row className='header m-0 p-0' style={{backgroundColor:"white"}}>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo1} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
        </div>
        </Col> 
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={130}/>
            </div> 
       </Col> 
        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#462f46",paddingBottom:"15px"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"white",}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto"
            navbarScroll
          >
            <Nav.Link href="/#/admin/dashboard" className=' mx-2 text-white'>Home</Nav.Link>

            <NavDropdown  id="basic-nav-dropdown" className=' mx-2 text-white'
              title={<span className="text-white my-auto">Members</span>}>
              <NavDropdown.Item href="/#/admin/member/mp" className="dropdown-item">Member Profiles</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/pa" className="dropdown-item">Photo Approval</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/paymentapprv" className="dropdown-item">Payment Approval</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/im" className="dropdown-item">Inactive Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/ppm" className="dropdown-item">Payment Pending Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/psm" className="dropdown-item">Payment Success Members</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/#/admin/afp" className=' mx-2 text-white'>Member Entry</Nav.Link>
            <Nav.Link href="/#/admin/r1" className=' mx-2 text-white'>Report</Nav.Link>
            <Nav.Link href="/#/scanPage" className=' mx-2 text-white'>Entry Pass</Nav.Link>
            

          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
       

    </Container> 
    {/* </div> */}
   
    </>
  )
}

export default AdminMasterHeader
import React, { useState,useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminPhotoApproval, adminPhotoApproveList } from '../../AllApi/AdminApi/AdminApi';

import { toast } from 'react-toastify';
import { MDBDataTable } from 'mdbreact';
import { Row, Table } from 'react-bootstrap';

const PhotoApproval = () => {


   //----------------------------------------------------------------------------
  
    const [record, setRecord] = useState([])  
    const [data, setData] = useState([]);
    const [photoApprovalObj, setphotoApprovalObj] = useState()
    const [showLargePhoto, setShowLargePhoto] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedMember, setSelectedMember] = useState();
    const [showOperationButtons, setShowOperationButtons] = useState(false);
    const [Regid, setRegid] = useState() ;
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
  
  //----------------------------------API code------------------------------------------


  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          // 'http://192.168.29.112:9081/api/admin/photoApprovalList',
          adminPhotoApproveList,
          tokenConfig);
        setRecord(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
  fetchMemberData()
}, [])
  

    //--------------------------Image click code-----------------------------------
    const handleButtonClick = (member) => {
      setSelectedPhoto(member.photo);
      setSelectedMember(data);
      setShowOperationButtons(true); 
      setShowLargePhoto(true);
      setRegid(member.reg_id)
      const obj=record.find((item) => item.reg_id === member.reg_id);
    setphotoApprovalObj(obj)
    };
  
    const handleCloseLargePhoto = () => {
      setShowOperationButtons(false); 
      setShowLargePhoto(false);
      setSelectedPhoto(null);
      setSelectedMember(null);
    };

    const handleOperationClick = (operation) => {
      let updatedImageStatus 
      if (operation === 'approve') {
        updatedImageStatus = "1";
        
        toast.success('Photo Approved Successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        window.location.reload();

      } else if (operation === 'reject') { 
        updatedImageStatus = "2";

        toast.error('Photo Rejected !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        window.location.reload();
      }  
     
      const data={
        reg_id:photoApprovalObj.reg_id,
        image_status:updatedImageStatus 
      }
   
      axios.put(adminPhotoApproval,data)
      .then((res) => {
      })
      .catch((err) => console.log(err))
      handleCloseLargePhoto();
    };    

//=====================================DATA TABLE============================================
const footerStyle = {
  backgroundColor: '#462f46',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
}

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name_en',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'email_id',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'View',
    field: 'action',
  },
];


const customRows = record.map((item, index) => {
  const { gender, name_en, mobileno, email_id,reg_id,member_id } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    reg_id,
    member_id,
    name_en,
    gender:genderLabel,
    mobileno,
    email_id,
    action: (
      <button className="viewButtons1" type='submit' onClick={() => handleButtonClick(item)}>View Photo</button>
      
    ),
  };
});


  return (
   
     <>
    <div className="row m-0" >
    <div className='p-0'> <AdminMasterHeader/> </div>

    <div className="filter-buttons row p-0">
        <div className='col-12 pt-1' style={{display:"flex",alignItems:"center"}}>
        <h4 style={{ fontWeight: '550',textAlign:"start"}}>Photo Approval</h4>
        </div>
      </div>
    
     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className="col-12 col-sm-10">
        <MDBDataTable
          className='mb-5 custom-datatable'
          striped
          bordered
          hover
          small
          paging={!showLargePhoto}
          data={{ columns, rows: customRows }}
          style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>
     
     
      {showLargePhoto && (
        <div className="large-photo-modal">
          <div className="modal-contentPhotoApp col-12 col-md-4">
          <span className="close ms-auto fs-1" onClick={handleCloseLargePhoto}>&times;</span>
            <div className='col-12' style={{textAlign:"center",display:"flex",height:"350px"}}>
            <img
              src={`nodeApp/image/${photoApprovalObj.image}`}
              alt={`${photoApprovalObj.name_en}'s Large Photo`}
              className="large-photo"
            />
          </div>
            {showOperationButtons && (
              <div className="operation-buttons">
                <button className='button1' id='btn' onClick={() => handleOperationClick('approve')}>Approve</button>
                <button className='button2' id='btn' onClick={() => handleOperationClick('reject')}>Reject</button>
              </div>
            )}
          </div>
        </div>
      )}

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
     
    </>


  )
}

export default PhotoApproval
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminDashboard,adminDashboardEducation } from '../AllApi/AdminApi/AdminApi';
import '../AdminComponents/AdminLogin.css';

const AdminDashboard = () => {
  const navigate = useNavigate()
  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])

  //---------------------------------Disabled back button-------------------------------
  useEffect(() => {
    window.history.pushState(null, null, window.location.href)

    const handleBackButton = (event) => {
      event.preventDefault()
      window.history.forward()
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  //-------------------------- Authenticate User from session----------------------------------

  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/admin')
    }
  }, [isLoggedIn, navigate])

  //--------------------------API data-------------------------------

  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

     //API for Total & Todays members-------------------------------------------

    const fetchData1 = async () => {
      try {
        const response = await axios.get(
          // 'http://192.168.29.112:9081/api/admin/DashBoard',
          adminDashboard,
          tokenConfig,
        )
        setRecord(response.data)
      
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData1()
     
    //API for Education Category-----------------------------------------------
    
    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          // 'http://192.168.29.112:9081/api/admin/educationCategory',
          adminDashboardEducation,
          tokenConfig,
        )
        setRecord1(response.data)  
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData2()
  }, [])



 


  //-------------------------CSS----------------------------
  const point1 = {
    backgroundColor: "#b3b3d6",
    backgroundImage:"radial-gradient( circle farthest-corner at 10% 20%,  rgba(178,178,212,1) 0%, rgba(253,222,249,1) 79.5% )",
    borderRadius: '5px',
    color: 'black',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
  const point2 = {
    backgroundColor: "#FFDEE9",
    backgroundImage:"linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%)",
    borderRadius: '5px',
    color: 'black',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
  const point3 = {
    backgroundColor: "#0093E9",
    backgroundImage:"linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
    borderRadius: '5px',
    color: 'white',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
 const tableHead= {
  backgroundColor: "#462f46",
  backgroundImage:"linear-gradient( 179deg,  rgba(255,129,27,1) -27.5%, rgba(245,255,56,1) 94.7% )",
  color:"red"
 }

  return (
    <>
      <div className="row g-0">
        <div>
          {' '}
          <AdminMasterHeader />{' '}
        </div>

           {/* Todays  */}
            <div className='row m-0 mt-md-5' style={{display:"flex",justifyContent:"center",}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Todays Male<br />
                {record.todaysMaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Todays Female<br />
                {record.todaysFemaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Todays Payment<br />
                Rs. {" "} {record.todaysPaymentSum}
              </div>

            </div>

            {/* Total */}
            <div className='row m-0' style={{display:"flex",justifyContent:"center"}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Total Male<br />
                {record.totalMaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Total Female<br />
                {record.totalFemaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point1}
              >
                Total Payment<br />
                Rs. {" "} {record.totalPaymentSum} 
              </div>

            </div>
             {/*============================= Extra ================================*/}
            {/* -------Melava Count------- */}
            <div className='row m-0' style={{display:"flex",justifyContent:"center"}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point2}
              >
                First Marriage Melava<br />
                {record.FirstMarriageMelava}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point2}
              >
                Vidhava Vidhoor Melava<br />
                {record.VidhavaVidhooMelava}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point2}
              >
                Both Melava<br />
               {record.BothMelava} 
              </div>

            </div>
            {/* -------Payment Count------- */}
            <div className='row m-0' style={{display:"flex",justifyContent:"center"}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point3}
              >
                Payment Success<br />
                {record.paymentSuccess}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point3}
              >
                Payment Pending<br />
                {record.paymentPending}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                style={point3}
              >
                Payment Approval Pending<br />
                {record.adminApproval} 
              </div>

            </div>
            {/* Education Category */}
            <div className='row m-0 pb-5 mt-5 education' style={{display:"flex",justifyContent:"center",}}>

             <div className="col-12 col-md-6" style={{display:"flex",justifyContent:"center",}}>
             <table className='mt-5' style={{backgroundColor:"white"}}>
                <thead style={{color:"white"}}>
                 <tr style={tableHead}>
                 <th>Education Category</th>
                 <th>Total Count</th>
                 </tr>
                </thead>

                <tbody>
                {record1.map((item, index) => {
                    return (
                  <tr key={index}>
                    <td> {item.education_name}</td>
                    <td>{item.count}</td>
                  </tr>
                  )
                  })}
                  <tr style={{color:"red"}}>
                    <td>Total Registration</td>
                    <td>{record.totalMaleCount+record.totalFemaleCount}</td>
                  </tr>
                </tbody>
              </table>
             </div>


             <div className="col-12 col-md-6" style={{display:"flex",justifyContent:"center"}}>
             <table className='mt-5' style={{backgroundColor:"white"}}>
                <thead  style={{width:"300px",justifyContent:"center"}}>
                  <tr style={tableHead}>
                  <th>Marital Status</th>
                  <th>Male</th>
                  <th>Female</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Unmarried</td>
                    <td>{record.UnmarriedMaleCount}</td>
                    <td>{record.UnmarriedFemaleCount}</td>
                  </tr>
                  <tr>
                    <td>Divorcee</td>
                    <td>{record.DivercyMaleCount}</td>
                    <td>{record.DivercyFemaleCount}</td>
                  </tr>
                  <tr>
                    <td>Widower/Widow</td>
                    <td>{record.VidowMaleCount}</td>
                    <td>{record.VidowFemaleCount}</td>
                  </tr>
                  <tr>
                    <td>Disabled</td>
                    <td>{record.totalMaleDisabledCount}</td>
                    <td>{record.totalFemaleDisabledCount}</td>
                  </tr>
                </tbody>
              </table>
             </div>


            </div>
      
      </div> 
    </>
  )
}

export default AdminDashboard

import React, {useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import {useNavigate} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../assets/logo2.png';
import Logo2 from '../../assets/kolLogo.png';

const UserMasterHeader = () => {

  const navigate=useNavigate()

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }

  useEffect(() => {
    let token=sessionStorage.getItem("token");
    let userdata=sessionStorage.getItem("userdata");

    if(token==null || userdata==null){
      sessionStorage.clear()
      navigate("/")
    }
  }, [])
  
  return (
    <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center",color:"rgb(57, 29, 28)"}}>
       <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
            </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>

        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link  onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

      {/* <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233, 224, 227)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/userDashboard" className=' mx-2 text-white'>Home</Nav.Link>
            <Nav.Link href="/user/regForm" className=' mx-2 text-white'>Registration</Nav.Link>
            <Nav.Link href="/" className=' mx-2 text-white'>Payment</Nav.Link>
            <Nav.Link href="/paymentRecipt" className=' mx-2 text-white'>Download Receipt</Nav.Link>
            <Nav.Link href="" className=' mx-2 text-white'>Check Printing Data</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar> */}
       

    </Container> 
    </div>
  )
}

export default UserMasterHeader
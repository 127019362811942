import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import '../AdminComponents/AdminLogin.css'
import { adminLogin } from '../AllApi/AdminApi/AdminApi';
import Nav from 'react-bootstrap/Nav';
import Logo from '../assets/Mainlogo.jpg';
import background1 from '../assets/background1.jpg'
import kolLogo from '../assets/kolLogo.png'
import login from '../assets/login.png'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader';
import { toast } from 'react-toastify';

const AdminLogin = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleEmail = (e) => {
    setEmail(e.target.value)
    setIsValid(true)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
    setIsValid(true)
  }

  const loginFun = async (e) => {
    e.preventDefault()
    if (email === '' || password === '') {
      setIsValid(false)
      return
    }

    const logObj = {
      email: email,
      password: password,
    }

    try {
      const response = await axios.post(
        // 'http://192.168.29.112:9081/api/admin/adminlogin',
        adminLogin,
        logObj,
      )

      if (response.data === 'NOTFOUND') {
        toast.error('Invalid login details !!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else if (response.data !== null) {

        const token = response.data.token
        axios.defaults.headers.common['token'] = `Bearer ${token}`

        sessionStorage.setItem('userdata',JSON.stringify(response.data.admindata),
        )
        sessionStorage.setItem('token','Bearer ' + response.data.token)
        navigate('/admin/dashboard')
      } else {
        alert('Invalid login details')
      }
    } catch (error) {
      console.error('---Error------', error)
      alert('Server Error') 
    }
  }

  const logoutFun = () => {
    navigate("/");    
  }

  //--------------------------------------------------------------------------------
  //------------------------------------CSS--------------------------------------------

  const box = {
    height: '80vh',
    margin: '60px 20px 60px 20px',
    textAlign: 'center',
    display:"flex",
    justifyContent:"center",
    width:"100%",
  }
  const input = { padding: '5px 10px', marginBottom: '20px', borderRadius:"5px", border:"none" }
 
  const button = {
    padding: '5px 20px',
    color: 'white',
    border:"none",
    backgroundColor:"#ff6600",
    borderRadius:"5px",
  }
  const title = { textAlign: 'center', paddingBottom: '20px' }

  const footerStyle = {
    backgroundColor:"rgb(233, 224, 227)",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#000",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
    display:"flex",
    justifyContent:"center"
  }
  const style={
    height:"100%",
    display: 'flex',
    margin: '0px',
    justifyContent: 'center',
    backgroundImage: `url(${background1})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
 
  const imgStyle={
    width:"100px",
    height:"100px"
  }

  return (
    <div>
      <Row style={style} >
        <div style={box}>
          <div className='row p-0 loginform'>

          <div className="col-sm-6 p-0 m-0 mb-3 mb-sm-0" style={{borderRadius:"10px",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <div className='ps-xxl-2'>
               <img className='loginImg' src={login} alt="img not found" />
            </div>
          </div>

            <div className="col-sm-6 p-0 m-0" style={{display:"flex",flexDirection:"column",height:"100%",}}>

             
                <div className="col-12 m-0 text-center" style={{height:"40%",paddingTop:"10px",color:"#c82890"}}>
                <img className='mt-5 mt-sm-0' src={kolLogo} style={imgStyle} alt="img not found" />
                  <p className='p-0 m-0 pt-1' style={{fontSize:"14px",fontWeight:"bold"}}>दक्षिण भारत जैन सभा,वधू वर सूचक मंच, कोल्हापूर विभाग</p>
                  <p className='p-0 m-0' style={{fontSize:"18px",fontWeight:"bolder",textShadow:"1px 1px 1px"}}>दिगंबर जैन बोर्डिंग, कोल्हापूर</p>
                  <p className='p-0 m-0' style={{fontSize:"15px",fontWeight:"bold"}}>जैन वधू - वर नोंदणी २०२३</p>
                </div>

                <div className="col-12 mt-5 mt-sm-0" style={{height:"60%",paddingTop:"30px"}}>

                <h4 className='pt-3'>Admin Login</h4>
                  <Form className='col-12 mt-3'>
                    <Form.Group controlId="formBasicEmail">
                      <input
                        className='col-10'
                        style={input}
                        type="email"
                        placeholder="Username/ Email"
                        value={email}
                        onChange={handleEmail}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <input
                        className='col-10'
                        style={input}
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handlePassword}
                      />
                    </Form.Group>

                    {isValid ? null : (
                      <div style={{ color: '#ff6600', margin: '10px',marginBottom:"-10px" }}>
                        All fields are compulsory*
                      </div>
                    )}
                    <button style={button} type="submit" onClick={loginFun} className='col-10'> 
                      Login
                    </button>
                  </Form>
                </div>
          

            </div>

          </div>
        </div>
      </Row>
      
    </div>
  )
}

export default AdminLogin

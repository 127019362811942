import React, { useState, useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import reject from '../../assets/reject.jfif'
import kolLogo from '../../assets/kolLogo.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { MDBDataTable } from 'mdbreact';
import { adminImgPath, adminMemberList } from '../../AllApi/AdminApi/AdminApi'

const MemberProfiles = ({}) => {
  const navigate = useNavigate()
  const [record, setRecord] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredMembers, setFilteredMembers] = useState(record)
  const [showLargePhoto, setShowLargePhoto] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [showOperationButtons, setShowOperationButtons] = useState(false)
  const [isViewingProfile, setIsViewingProfile] = useState(false)
  const [genderFilter, setGenderFilter] = useState('All')
  const [mobilenoFilter, setMobilenoFilter] = useState('All')
  const [Image, setImage] = useState()
  const [occupationFilter, setOccupationFilter] = useState('All')


  //----------------------------------API code------------------------------------------

  useEffect(() => {

    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          // "http://192.168.29.112:9081/api/registration/select/list"
          adminMemberList,
          tokenConfig,
        )
        setRecord(response.data)
        setFilteredMembers(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchMemberData()
  }, [])

  //---------------------------Search code---------------------------------------

  const handleSearchChange1 = (event) => {
    const query = event.target.value
    setSearchQuery(query)

    let filtered = record.filter(
      (member) =>
        member.en_fname.toLowerCase().includes(query.toLowerCase()) ||
        member.email_id.toLowerCase().includes(query.toLowerCase()),
    )
    if (genderFilter !== 'All') {
      filtered = filtered.filter(
        (member) => member.gender === parseInt(genderFilter),
      )
    }
    
    if (occupationFilter !== 'All') {
      filtered = filtered.filter(
        (member) => member.occupation === occupationFilter,
      )
    }
    setFilteredMembers(filtered)
  }

  //------------------------Filter code--------------------------------------------
  const applyFilters = (query, gender,occupation) => {
    const filtered = record.filter(
      (member) =>
        member.en_fname.toLowerCase().includes(query.toLowerCase()) &&
        (gender === 'All' ||
          (gender === 'Male' && member.gender === 1) ||
          (gender === 'Female' && member.gender === 2)) &&
        (occupation === 'All' || member.occupation === occupation),
    )
    setFilteredMembers(filtered)
  }

  const handleFilterChange = (gender,occupation) => {
    setGenderFilter(gender)
    setOccupationFilter(occupation)
    applyFilters(searchQuery, gender,occupation)
  }

  //--------------------------Image click code-----------------------------------
  const handleViewButtonClick = (member) => {
    setSelectedMember(member)
    setIsViewingProfile(true)
    setShowLargePhoto(true)
  }

  const handleCloseLargePhoto = () => {
    setShowOperationButtons(false)
    setShowLargePhoto(false)
    setSelectedPhoto(null)
    setSelectedMember(null)
  }

  //-------------------------------------------------------------
  const handleEditDataButton = (member) => {
    const member_id = member.member_id
     navigate('/admin/members/update', { state:{member_id}})
  }

  //CSS for Information-------------------------------------------------------------------
  const info = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
    backgroundColor: '#b3b3d6',
    color: 'black',
    fontWeight:"550px"
  }
  const footerStyle = {
    backgroundColor: '#462f46',
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '12px',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }
//==========overlay code=================================
  const overlay={
  position:" absolute",
  bottom:"200px", 
  background: "rgb(0, 0, 0)",
  background: "rgba(0, 0, 0, 0.5)",
  color:" #f1f1f1", 
  width: "200px",
  transition: ".5s ease",
  opacity:"1",
  color: "red",
  fontSize:" 20px",
  padding: "10px",
  textAlign: "center",
  fontWeight:"bolder",
  }

  //================================================table==================================

  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Member Id',
      field: 'member_id',
      sort: 'asc',
    },
    {
      label: 'Reg.Id',
      field: 'reg_id',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Gender',
      field: 'gender',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'email_id',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mobileno',
      sort: 'asc',
    },
    {
      label: 'Payment Status',
      field: 'payment_status',
      sort: 'asc',
    },
    {
      label: 'View',
      field: 'action',
    },
    {
      label: 'Edit',
      field: 'action1',
    },
  ];
  
  
  const customRows = filteredMembers.map((item, index) => {
    const { gender, en_fname,en_mname,en_lname, mobileno, email_id,member_id,reg_id,payment_status } = item;
    const genderLabel = gender === 1 ? 'Male' : 'Female';

    let paymentStatusLabel, backgroundColor,textColor;

    switch (payment_status) {
      case 0:
        paymentStatusLabel = 'Pending';
        backgroundColor = 'orange';
        break;
      case 1:
        paymentStatusLabel = 'Approval Pending';
        backgroundColor = 'yellow';
        break;
      case 2:
        paymentStatusLabel = 'Success';
        backgroundColor = 'green';
        textColor = 'white';
        break;
        case 3:
        paymentStatusLabel = 'Reject';
        backgroundColor = 'red';
        textColor = 'white';
        break;
      default:
        paymentStatusLabel = '';
        backgroundColor = '';
    }

    return {
      srNo: index + 1,
      reg_id,
      member_id,
      payment_status: (
        <span style={{ backgroundColor, color: textColor,padding:"5px 10px" }}>{paymentStatusLabel}</span>
      ),
      name: `${en_fname} ${en_mname} ${en_lname}`,
      gender:genderLabel,
      mobileno,
      email_id,
      action: (
        <button className="viewButtons1" type='submit' onClick={() => handleViewButtonClick(item)}>View Profile</button>
        
      ),
      action1: (
        <button className="viewButtons1" type='submit' onClick={() => handleEditDataButton(item)}>Edit Profile</button>
        
      ),
    };
  });
 

  //==========================================================================================================
  useEffect(() => {
    const handleModal = () => {
      if (showLargePhoto) {
        document.body.classList.add('hide-page-numbers');
      } else {
        document.body.classList.remove('hide-page-numbers');
      }
    };

    document.body.addEventListener('modaltoggle', handleModal);

    return () => {
      document.body.removeEventListener('modaltoggle', handleModal);
    };
  }, [showLargePhoto]);


  return (
    <>
      <div className="row m-0">
        <div className="p-0">
          {' '}
          <AdminMasterHeader />{' '}
        </div>

        <div className="filter-buttons row">
          <div className="col-12 col-md-4" style={{display:"flex",alignItems:"center"}}>
            <h4 style={{ fontWeight: '550' }}>Member Profiles</h4>
          </div>

          <div className="filterButtonsBack col-12 col-md-8">
            <Link
              className="filterButtons"
              onClick={() => handleFilterChange('All', 'All')}
            >
              All Members
            </Link>

            <NavDropdown
              className="filterButtons mx-2 "
              id="basic-nav-dropdown"
              title={<span className=" my-auto text-center">Male Members</span>}
            >
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'All')}
              >
                All Male
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Business')}
              >
                Business
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Government Job')}
              >
                Government Job
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Private Job')}
              >
                Private Job
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Professional')}
              >
                Professional
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Agricultural')}
              >
                Agricultural
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Studying')}
              >
                Studying
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Not Working')}
              >
                Not Working
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="filterButtons mx-2"
              id="basic-nav-dropdown"
              title={<span className=" my-auto">Female Members</span>}
            >
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'All')}
              >
                All Female
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Business')}
              >
                Business
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Government Job')}
              >
                Government Job
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Private Job')}
              >
                Private Job
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Professional')}
              >
                Professional
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Agricultural')}
              >
                Agricultural
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Studying')}
              >
                Studying
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Not Working')}
              >
                Not Working
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>


        <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10">
              <MDBDataTable
                className='mb-5 custom-datatable'
                striped
                bordered
                hover
                small
                paging={!showLargePhoto}
                data={{ columns, rows: customRows }}
                style={{ textAlign: "center", fontSize: "14px" ,}}/>
          </div>
          <div className="col-12 col-sm-1"></div>
          </Row>


        {showLargePhoto && (
          <div className="large-photo-modal">
            <div className="modal-content">
              <Row className="header m-0" style={{ boxShadow:"2px 2px 5px",backgroundColor:"#fff" }}>
                <Col
                  md={4}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={kolLogo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={7}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'start',color:"#8c095e" }}
                >
                  <div className="text-center">
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभा,वधू वर सूचक मंच, कोल्हापूर विभाग</h5>
                    <h3 className="fw-bolder">
                    दिगंबर जैन बोर्डिंग, कोल्हापूर{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी २०२३</h5>
                  </div>
                </Col>

                <Col
                  md={1}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'end', }}
                >
                  <div className="text-center">
                  <span
                    title="Close"
                    className="close ms-auto fs-1 me-3"
                    onClick={handleCloseLargePhoto}
                  >
                    &times;
                  </span>
                  </div>
                </Col>
              </Row>

              
              {isViewingProfile && (
                <div className="row profile-details m-0">
                  <div
                    className="col-12 col-md-3"
                    style={{
                      justifyContent: 'start',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '300px',margin:"19px 0px 0px 0px",
                     
                    }}
                  >
                
                  <img
                    src={`nodeApp/image/${selectedMember.image}`}
                    alt={`${selectedMember.en_fname}'s Photo`} style={{height:"250px",backgroundColor:"#fff"}}
                    className="small-photo mb-2 border"
                  />
                
                  </div>

                  <div className="col-12 col-md-9 pt-3">
                    {/* Basic Information */}
                    <div
                      className="row m-0 border"
                      style={{ backgroundColor: '#fff',boxShadow:"1px 1px 3px" }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Basic Information/मुलभूत माहिती</h6>
                      </div>
                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>First Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.en_fname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Last Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>पहिले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>मधले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.mr_mname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>आडनाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Gender/लिंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "} {selectedMember.gender === 1 ? 'Male' : 'Female'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Marital Status/वैवाहिक स्थिती</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}
                          {/* {selectedMember.marital_status} */}
                          {selectedMember.marital_status == "1"
                                      ? 'Unmarried'
                                      : selectedMember.marital_status == "2"
                                      ? 'Divorcee'
                                      : selectedMember.marital_status == "3"
                                      ? 'Widow'
                                      : 'NA'
                                    }
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Complextion/रंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.complextion}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Disability/दिव्यांग</p>
                          </div>
                          <div className="ccol-6 col-md-8">
                            :{' '}{selectedMember.disability == "1" ? 'No' : 'Yes'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Melava/मेळावा</p>
                          </div>
                          <div className="ccol-6 col-md-8">
                            :{' '}  
                            {/* {selectedMember.melava_type} */}
                            {selectedMember.melava_type == "1"
                                      ? 'Single Melava'
                                      : selectedMember.melava_type == "2"
                                      ? 'Vidhva Vidhoor Melava'
                                      : selectedMember.melava_type == "3"
                                      ? 'Both Melava'
                                      : 'Unknown Melava Type'
                                    }
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Date of birth/जन्मतारीख</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.dob} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Birth time/जन्मवेळ</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.birth_time}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Height/उंची</p>
                          </div>
                          <div className="col-6 col-md-8"> :{" "}{selectedMember.height} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Subcast/पोटजात</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.subcastename}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Education Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff',boxShadow:"1px 1px 3px" }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p> Education/शिक्षण </p> 
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.education_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                           Education Category/शिक्षण श्रेणी
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.subeducation_name}
                          </div>
                        </div>

                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                            Other Qualification(इतर पात्रता)
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.other_education}
                          </div>
                        </div>

                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation Type/ व्यवसायाचा प्रकार
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.occupation}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Annual Income/वार्षिक उत्पन्न</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {selectedMember.amount} </div>
                        </div>

                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Occupation & Addrress/व्यवसाय आणि पत्ता</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {selectedMember.occupation_detail} </div>
                        </div>

                      </div>
                    </div>

                    {/* Family Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff',boxShadow:"1px 1px 3px" }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Family Information/कौटुंबिक माहिती</h6>
                      </div>
                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father First Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.en_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Last Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_lname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fatherfname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे मधले नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{selectedMember.mr_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे आडनाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fatherlname}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Acar/शेत जमीन एकर</p>
                          </div>
                          <div className="col-6">:{" "}{selectedMember.farm_ac}</div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Guntha/शेत जमीन गुंठे</p>
                          </div>
                          <div className="col-6">:{" "}{selectedMember.farm_g}</div>
                        </div>
                        <div className="col-12 d-flex">
                          <div className="col-6 col-md-3 para">
                            <p>Other Property/इतर मालमत्ता</p>
                          </div>
                          <div className="col-6 col-md-9">
                          :{" "}{selectedMember.other_property}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Brother/भाऊ:</p>
                          </div>
                            <div className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {selectedMember.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {selectedMember.b_married}
                              </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Sister/बहीण:</p>
                          </div>
                            <div  className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {selectedMember.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {selectedMember.s_married}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff',boxShadow:"1px 1px 3px" }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Contact Information/संपर्क माहिती</h6>
                      </div>
                      <div
                        className="col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3  para">
                            <p>Address(English)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {selectedMember.en_address}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>Village/City(English)</p>
                          </div>
                          <div className="col-8 ps-3">
                          :{" "}{selectedMember.en_village}
                          </div>
                        </div>
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3 para">
                            <p>पत्ता(मराठी)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {selectedMember.mr_address}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>गाव/शहर(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.mr_village}{' '}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>State(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.stateenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>District(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.districtenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>Taluka(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.talukaenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>राज्य(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.statemrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>जिल्हा(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.districtmrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>तालुका(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.talukamrname}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.email_id}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-1/मोबाईल नंबर-1
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.mobileno}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-2/मोबाईल नंबर-2
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.mobileno2}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Expectations about spouse */}
                    <div
                      className="row m-0 border mt-3 mb-3"
                      style={{ backgroundColor: '#fff' ,boxShadow:"1px 1px 3px"}}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Expectations About Spouse/जोडीदाराबद्दल अपेक्षा</h6>
                      </div>
                      <div className=" col-12 p-2">
                        <div className="row d-flex">
                          <div className="col-5 col-md-3  para">
                            <p>Expectations/अपेक्षा:</p>
                          </div>
                          <div className="col-7 col-md-9"> <p>{selectedMember.exp_housewife === 1 ? 'गृहिणी' : null} &nbsp; {selectedMember.exp_compatible === 1 ? 'अनुरूप' : null} &nbsp; {selectedMember.exp_farmer === 1 ? 'शेती' : null}{" "}{selectedMember.exp_serviceman === 1 ? 'नोकरी' : null} &nbsp; {selectedMember.exp_businessman === 1 ? 'व्यवसाय' : null} &nbsp; </p></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default MemberProfiles

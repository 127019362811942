import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import afterlogo from '../../assets/login-after.png';
import QRlogo from '../../assets/Payment QR.jpg';
import BankImg from '../../assets/Bank Details.jpg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter';
import EntryHeader from '../../CommonComponenets/EntryHeader';
import { PaymentAdd, RegistrationSelect } from '../../AllApi/AdminApi/AdminApi';

const PaymentPage1 = () => {
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const navigate = useNavigate()
  const data = JSON.parse(sessionStorage.getItem("status"))
  const [Image, setImage] = useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const userid = userdata.member_id
  const regid = userdata.reg_id
  const member_id = userdata.member_id
  const [transactionId, setTransactionId] = useState()
  const [amount, setAmount] = useState();
  const [Userdata, setUserdata] = useState({});
  const [melavaType, setMelavaType] = useState(0);
  const currentDate = new Date();

  const validateFileSize = (file, maxSizeMB, errorMessage) => {
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      if (fileSizeInMB > maxSizeMB) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    }
    return true;
  };


  const NewData = (e) => {
    e.preventDefault();
    if (!Image) {
      toast.error("Please select a photo before submitting.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    setIsLoading(true);

    if (!validateFileSize(Image, 1, 'Image size exceeds the maximum limit of 1MB.')) {
      setIsLoading(false);
      return;
    }

    let formData = new FormData();
    const status = "0"
    formData.append("file", Image);
    formData.append("member_id", userid);
    formData.append("taxid", transactionId);
    formData.append("pay_amount", amount);
    formData.append("reg_id", regid);
    formData.append("pay_date", currentDate.toISOString());

    const member_id = formData.get("member_id");
    const taxid = formData.get("taxid");
    const reg_id = formData.get("reg_id");
    const pay_date = formData.get("pay_date");
    const pay_amount = formData.get("pay_amount");
    const file = formData.get("file");
    for (const entry of formData.entries()) {

    }


    const finaldata = {
      member_id: member_id,
      file: file,
      taxid: taxid,
      reg_id, reg_id,
      pay_date, pay_date,
      pay_amount, pay_amount,
    }

    console.log("Data", finaldata);
    axios
      .post(PaymentAdd, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        console.log("////////////////", res)
        setIsLoading(false);
        if (res.data === "SAVED") {
          toast.success('File Uploaded Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          navigate('/admin/dashboard')

        }
      })
      .catch((err) => {
        console.log("-*-*-*-*-*catch block*-*-*-*-*-*",err)
        setIsLoading(false);
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        const user = res.data[0];
        console.log("==============dfgsdfgsgsg", res)
        if (user !== null) {
          console.log("in use effect=== ", user.melava_type);
          setUserdata(user.data);
          setMelavaType(user.melava_type);

        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
  
    // Adjust the payment amount based on melva_type
    if (melavaType == '1') {
      setAmount(1000);
    } else if (melavaType == '2') {
      setAmount(500);
    } else if (melavaType == '3') {
      setAmount(1500);
    } else {
      setAmount(0);
    }
  }, [melavaType]);


  const setImgfun = (e) => {
    // setupdateimage(false)
    setImage(e.target.files[0])
  }
//===============download QR img=========================
const downloadQRCode = () => {
  const link = document.createElement('a');
  link.href = QRlogo;
  link.download = <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
  link.click();
};

//==========================================================================

  return (
    <Container fluid className='m-0 p-0'>
     <div className='m-0 p-0'>
        <EntryHeader />
      </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className=' rounded shadow  px-3 pb-2 py-sm-4' style={{ backgroundColor: "white" }} >
          <Row className='m-0'>
            <Col>
              <div className='mt-3 mt-md-0' style={{ display: "flex", justifyContent: "center", color: "rgb(134, 28, 18)" }}>
                <h5><b >Pay Your Registration Fee</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>

          <Row className='m-0 mt-4 mb-4'>
            <Col sm={6} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <img src={QRlogo} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />

             

              <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>Pay Payment :</b></h6></Form.Label>
              <Form.Control
                className='w-50 text-center'
                value={amount}
                readOnly  />

                 <Button className='mt-3 mt-md-2' style={{background:"rgb(134, 28, 18)",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button>
            </Col>
            <Col sm={6} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={BankImg} className='w-100 mb-2 p-3 ' />
            </Col>
          </Row>

          <Row>
            <Form
              onSubmit={NewData}
              encType="multipart/form-data">

              <Row className='px-4 mt-md-2'>
                <Col sm={12} md={6}>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>Payment Success Photo Upload :</b></h6></Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => setImgfun(e)}
                      required
                      name="file"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Label><h6 style={{ color: "rgb(134, 28, 18)" }}><b>UTR No. / UPI No. / Transcation Transfer No :</b></h6></Form.Label>
                  <Form.Control
                    sm={12}
                    md={4}
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    style={{
                      display: "flex",
                      backgroundColor: "rgb(134, 28, 18)",
                      border: "none",
                      justifyContent: "center",
                    }}
                    className="my-4 py-2 px-4 mt-5"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>

        </div>
      </Container>
      <div className='m-0 p-0'>
        <AdminMasterFooter />
      </div>



    </Container>
  )
}

export default PaymentPage1

// export const BaseIP = 'http://192.168.29.114:8091/';
// export const BaseIP = 'http://192.168.29.112:9081/';


export const BaseIP = 'https://jainboardingkolhapur.com/';

export const adminLogin= BaseIP+ "api/admin/adminlogin" // Admin login  (post)
export const adminDashboard= BaseIP+ "api/admin/DashBoard" // Admin Dashboard (get)
export const adminDashboardEducation=BaseIP+"api/admin/educationCategory"// Admin Dashboard Education Category (get)
export const adminMemberList= BaseIP+ "api/registration/select/list" // Admin member profile list (get)
export const adminPhotoApproveList=BaseIP+"api/admin/photoApprovalList"// Admin Photo Approve list (get)
export const adminPaymentList=BaseIP+"api/payment/findall"// Admin Disability list (get)
export const adminPhotoApproval=BaseIP+'api/admin/photoStatusSaveApproval'   //Admin photo status approval (put)
export const adminPaymentApproval=BaseIP+'api/payment/paymentApproval'   //Admin disability approval (put)
export const adminInactiveMemberList=BaseIP+'api/admin/inActiveMemberList'  //Admin inactive member list (get)
export const adminPaymentPendingList=BaseIP+'api/admin/paymentPendingList'  //Admin Payment pending list (get)
export const adminPaymentSuccessList=BaseIP+'api/admin/paymentSuccessList'  //Admin Payment success list (get)
export const adminUpdateProfile=BaseIP+'api/member/update/adminMemberProfile'  //Admin update profile (for edit page)
export const RegistrationSelect=BaseIP+"api/registration/select"//Personal detail api (for edit page) (get)

export const adminPaymentReportList=BaseIP+'api/admin/paymentSuccessList/Report'  //Admin payment report  list (get)


export const reportDataPDF=BaseIP+'api/registration/reportData' //Admin inactive list destroy


export const adminInactiveDestroy=BaseIP+'api/admin_logins/listDestroy' //Admin Report API

//======================edit data api from user==================
export const EducationMaster= BaseIP+ "api/education/select" //Education Master
export const SubeducationMaster= BaseIP+ "api/subeducation/select" //Education Master
export const subcastMaster= BaseIP+ "api/subcast/select" // SubCast master
export const IncomeMaster= BaseIP+ "api/income/select" // Income master
export const StateMaster= BaseIP+ "api/state/getState" // State master
export const DistrictMaster= BaseIP+ "api/district/getDistrict" // District master
export const TalukaMaster= BaseIP+ "api/taluka/getTaluka" // Taluka master


export const RegsitrationSave=BaseIP+"api/member/adminMemberCreate"// registrationSave
export const RegistrationUpdate=BaseIP+"api/registration/update" // checkbox api
export const RegistrationUpload=BaseIP+"api/registration/upload"//Upload api
export const PaymentAdd=BaseIP+"api/payment/add"//payment add"
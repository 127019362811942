import React, { useEffect,useState } from 'react';
import logonew from '../assets/logonew.jpg';
import {useNavigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AdminMasterFooter from './AdminMasterFooter';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';
import kolLogo from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';

const EntryHeader = () => {

    
  const navigate=useNavigate()

  const checkLogin = () => {
    const getSessionval = sessionStorage.getItem('userdata')
    if (!getSessionval) {
      navigate('/')
    }
   
  }

  useEffect(() => {
    checkLogin()
  }, [])


  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/admin");    
  }
  const footerStyle = {
    backgroundColor:"#660505",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    position:"fixed",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }
  const [activeOption, setActiveOption] = useState(window.location.hash);
  const handleItemClick = (hash) => {
    setActiveOption(hash);
  };
      
  return (
    <>

        <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box",boxShadow:"2px 2px 5px #462f46",}}>
      <Row className='header m-0 p-0'>
        <Col md={2} sm={12} style={{display:"flex",justifyContent:"center",}}>
            <div>
                <img src={kolLogo} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={8} sm={12} style={{color:"#8c095e",}}>
        <div className='text-center'>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा,वधू वर सूचक मंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
        </div>
        </Col> 
        <Col md={2} sm={12} style={{display:"flex",justifyContent:"end"}}>
            <Nav.Link onClick={logoutFun} style={{color:"#8c095e"}}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

      {/* <Navbar expand="lg" style={{backgroundColor: "#462f46",paddingBottom:"15px"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233,224,227)",}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto"
            navbarScroll
          >
            <Nav.Link href="/#/admin/dashboard" className=' mx-2 text-white'>Home</Nav.Link>

            <NavDropdown  id="basic-nav-dropdown" className='mx-2 text-white'
              title={<span className="text-white my-auto">Members</span>}>
              <style> {`.nav-item .dropdown-toggle::before,
                          .nav-item .dropdown-toggle::after{
                        border-top-color: white !important;
                      }`}</style>  
              <NavDropdown.Item href="/#/admin/member/mp" 
               onClick={() => handleItemClick('#/admin/member/mp')}
               className={` ${window.location.hash === '#/admin/member/mp' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/mp' ? '#b3b3d6' : 'initial' }}>Member Profiles</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/pa"
              onClick={() => handleItemClick('#/admin/member/pa')}
               className={` ${window.location.hash === '#/admin/member/pa' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/pa' ? '#b3b3d6' : 'initial' }}>Photo Approvals</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/photoapprv"
               onClick={() => handleItemClick('#/admin/member/photoapprv')}
               className={` ${window.location.hash === '#/admin/member/photoapprv' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/photoapprv' ? '#b3b3d6' : 'initial' }}>Payment Approve</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/im"
              onClick={() => handleItemClick('#/admin/member/im')}
               className={` ${window.location.hash === '#/admin/member/im' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/im' ? '#b3b3d6' : 'initial' }}>Inactive Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/ppm"
              onClick={() => handleItemClick('#/admin/member/ppm')}
               className={` ${window.location.hash === '#/admin/member/ppm' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/ppm' ? '#b3b3d6' : 'initial' }}>Payment Pending Members</NavDropdown.Item>
              <NavDropdown.Item href="/#/admin/member/psm"
               onClick={() => handleItemClick('#/admin/member/psm')}
               className={` ${window.location.hash === '#/admin/member/psm' ? 'active-option' : ''}`}
               style={{ backgroundColor: activeOption  === '#/admin/member/psm' ? '#b3b3d6' : 'initial' }}>Payment Success Members</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/#/admin/afp" className=' mx-2 text-white'>Member Entry</Nav.Link>
            <Nav.Link href="/#/admin/r1" className=' mx-2 text-white'>Report</Nav.Link>
            <Nav.Link href="/#/scanPage" className=' mx-2 text-white'>Entry Pass</Nav.Link>
            

          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar> */}
       

    </Container> 
    </div>

   
   
    </>
  )
}

export default EntryHeader
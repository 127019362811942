import React, { useState, useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios'
import {
  RegistrationSelect,
  adminPaymentApproval,
  adminPaymentList,
} from '../../AllApi/AdminApi/AdminApi'

import { toast } from 'react-toastify'
import { Row, Table, Col } from 'react-bootstrap'
import kolLogo from '../../assets/kolLogo.png'
import Logo1 from '../../assets/logo2.png'
import { MDBDataTable } from 'mdbreact'

const PaymentApproval = ({}) => {
  // ----------------------------------------------------------------

  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])
  const [disabilityobj, setdisabilityobj] = useState()
  const [showLargePhoto, setShowLargePhoto] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [showOperationButtons, setShowOperationButtons] = useState(false)
  const [Regid, setRegid] = useState()

  const [isViewingProfile, setIsViewingProfile] = useState(false)

  const [member_id, setMember_id] = useState()

  const [aadharCheckbox, setAadharCheckbox] = useState(false)
  const [paymentCheckbox, setPaymentCheckbox] = useState(false)
  const [modalData, setModalData] = useState()

  //===================================================================

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'aadhar') {
      setAadharCheckbox(!aadharCheckbox)
    } else if (checkboxType === 'payment') {
      setPaymentCheckbox(!paymentCheckbox)
    }
  }
  //----------------------------------API code------------------------------------------

  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          // 'http://192.168.29.112:9081/api/payment/findall',
          adminPaymentList,
          tokenConfig,
        )
        setRecord(response.data)
        setSelectedMember(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchMemberData()
  }, [])

  //--------------------------Image click code-----------------------------------

  const fetchMemberData1 = async (member) => {
    try {
      const response = await axios.get(
        `${RegistrationSelect}${member.member_id}`,
      )
      const data = response.data[0]

      setRecord1(data)
      setSelectedMember(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleImageClick = (member) => {
    setSelectedPhoto(member.photo)
    setSelectedMember(record1)
    setShowOperationButtons(true)
    setShowLargePhoto(true)
    setMember_id(member.member_id)
    setRegid(member.reg_id)
    setIsViewingProfile(true)
    fetchMemberData1(member)
    setModalData(member)
  }

  const handleCloseLargePhoto = () => {
    setShowOperationButtons(false)
    setShowLargePhoto(false)
    setSelectedPhoto(null)
    setSelectedMember(null)
  }

  const handleOperationClick = (operation) => {
    let updatedPaymentStatus
    if (operation === 'approve') {
      updatedPaymentStatus = 2

    

      window.location.reload();
      toast.success('Payment Approved Successfully!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      
    } else if (operation === 'reject') {
      updatedPaymentStatus = 3

      toast.error('Payment Rejected !!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })

      window.location.reload();
    }


    const data = {
      member_id: record1.member_id,
      reg_id: record1.reg_id,
      pay_amount:modalData.pay_amount,
      status: updatedPaymentStatus,
      
    }

    axios
      .put(adminPaymentApproval, data)
      .then((res) => {
      })
      .catch((err) => console.log(err))
    handleCloseLargePhoto()
  }

  //CSS for Information-------------------------------------------------------------------
  const info = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
    backgroundColor: '#b3b3d6',
    color: 'black',
    fontWeight: '550px',
  }
  const footerStyle = {
    backgroundColor: '#462f46',
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '12px',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }

  //=====================================DATA TABLE============================================
  
  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Member Id',
      field: 'member_id',
      sort: 'asc',
    },
    {
      label: 'Reg.Id',
      field: 'reg_id',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Gender',
      field: 'genderLabel',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'email_id',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mobileno',
      sort: 'asc',
    },
    {
      label: 'View Profile',
      field: 'action',
    },
  ]

  const customRows = record.map((item, index) => {
    const {gender,email_id,mobileno,name,member_id,reg_id} = item

    const genderLabel = gender === 1 ? 'Male' : gender === 2 ? 'Female' : 'NA'

    return {
      srNo: index + 1,
      member_id,
      reg_id,
      name,
      genderLabel,
      mobileno,
      email_id,
      action: (
        <button
          className="viewButtons1"
          type="submit"
          onClick={() => handleImageClick(item)}
        >
          View Profile
        </button>
      ),
    }
  })


  return (
    <>
      <div className="row m-0">
        <div className="p-0">
          {' '}
          <AdminMasterHeader />{' '}
        </div>
        <div className="filter-buttons row">
          <div
            className="col-12 pt-1"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <h4 style={{ fontWeight: '550', textAlign: 'start' }}>
              Payment Approval
            </h4>
          </div>
        </div>

        <Row
          className="row p-0 m-0"
          style={{
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
          }}
        >
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10">
            <style>{` .custom-pagination .pagination {display: none;}`}</style>
            <MDBDataTable
              className="mb-5 custom-datatable"
              striped
              bordered
              hover
              small
              data={{ columns, rows: customRows }}
              paging={!showLargePhoto}
              style={{ textAlign: 'center', fontSize: '14px' }}
            />
          </div>
          <div className="col-12 col-sm-1"></div>
        </Row>

        {showLargePhoto && (
          <div className="large-photo-modal">
            <div className="modal-content">
              <Row
                className="header m-0"
                style={{ backgroundColor: '#fff', boxShadow: '2px 2px 5px' }}
              >
                <Col
                  md={2}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={kolLogo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={7}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="text-center" style={{ color: '#8c095e' }}>
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभा,वधू वर सूचक मंच, कोल्हापूर विभाग</h5>
                    <h3 className="fw-bolder">
                     दिगंबर जैन बोर्डिंग, कोल्हापूर{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी २०२३</h5>
                  </div>
                </Col>

                <Col
                  md={2}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={Logo1}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={1}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <div className="text-center">
                    <span
                      title="Close"
                      className="close ms-auto fs-1 me-3"
                      onClick={handleCloseLargePhoto}
                    >
                      &times;
                    </span>
                  </div>
                </Col>
              </Row>

              {isViewingProfile && (
                <>
                  <div className="row profile-details m-0">
                    <div
                      className="col-12 col-md-3"
                      style={{
                        justifyContent: 'start',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '19px 0px 0px 0px',
                      }}
                    >
                      <img
                        src={`nodeApp/image/${record1.image}`}
                        alt={`${record1.en_fname}'s Photo`}
                        style={{ height: '250px', backgroundColor: 'white' }}
                        className="small-photo mb-2 border"
                      />
                      

                      {modalData && (
                        <>
                          <img
                            src={`nodeApp/adharcard_image/${modalData.adharcard_image}`}
                            alt={`${modalData.name}'s Aadhar Card`}
                            style={{
                              height: '500px',
                              backgroundColor: 'white',
                            }}
                            className="smallPhoto mb-2 border"
                          />

                          <img
                            src={`nodeApp/paymentImage/${modalData.tax_image}`}
                            alt={`${modalData.name}'s Payment Screen Shot`}
                            style={{
                              height: '400px',
                              backgroundColor: 'white',
                            }}
                            className="smallPhoto mb-2 border"
                          />


                          <div
                            className="col-12 p-2 border"
                            style={{ backgroundColor: 'white' }}
                          >
                            <p>
                              <span style={{fontWeight:"550"}}>UTR No./UPI No./Transaction Transfer No: </span>
                              {modalData.taxid}
                            </p>
                            <p><span style={{fontWeight:"550"}}>Transaction Date : </span>{modalData.pay_date}</p>
                            <p><span style={{fontWeight:"550"}}>Transaction Amount : </span>{modalData.pay_amount} ₹</p>
                          </div>
                        </>
                      )}
                    </div>


                    <div className="col-12 col-md-9 pt-3">
                      {/* Basic Information */}
                      <div
                        className="row m-0 border"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Basic Information/मुलभूत माहिती</h6>
                        </div>
                        <div
                          className="col-12 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>First Name (English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_fname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Middle Name(English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Last Name (English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_lname}
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-12 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>पहिले नाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>मधले नाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>आडनाव (मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_lname}
                            </div>
                          </div>
                        </div>

                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Gender/लिंग</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.gender === 1 ? 'Male' : 'Female'}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Marital Status/वैवाहिक स्थिती</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : 
                              {/* {record1.marital_status} */}
                              {record1.marital_status == '1'
                                ? 'Unmarried(Single)'
                                : record1.marital_status == '2'
                                ? 'Divorcee'
                                : record1.marital_status == '3'
                                ? 'Widow'
                                : 'Unknown Marital Status'}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Complextion/रंग</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.complextion}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Disability/दिव्यांग</p>
                            </div>
                            <div className="ccol-6 col-md-8">
                              : {record1.disability == '1' ? 'No' : 'Yes'}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p style={{backgroundColor:"yellow"}}>Melava/मेळावा</p>
                            </div>
                            <div className="ccol-6 col-md-8">
                              : <span style={{backgroundColor:"yellow"}}>{' '}
                              {/* {record1.melava_type} */}
                              {record1.melava_type == '1'
                                ? 'Single Melava'
                                : record1.melava_type == '2'
                                ? 'Vidhva Vidhoor Melava'
                                : record1.melava_type == '3'
                                ? 'Both Melava'
                                : 'Unknown Melava Type'}</span>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Date of birth/जन्मतारीख</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.dob}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Birth time/जन्मवेळ</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.birth_time}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Height/उंची</p>
                            </div>
                            <div className="col-6 col-md-8">
                              {' '}
                              : {record1.height}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Subcast/पोटजात</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.subcastename}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Education Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>
                            Education & Occupation Information/शिक्षण आणि
                            व्यवसाय माहिती
                          </h6>
                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Education/शिक्षण</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.education_name}
                            </div>
                          </div>
                          <div className="col-12 col-md-6  d-flex">
                            <div className="col-6 col-md-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Education Category/शिक्षण श्रेणी
                              </p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.subeducation_name}
                            </div>
                          </div>
                          <div className="col-12 col-md-6  d-flex">
                            <div className="col-6 col-md-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Occupation Type/ व्यवसायाचा प्रकार
                              </p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.occupation}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Annual Income/वार्षिक उत्पन्न</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.amount}{' '}
                            </div>
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation & Addrress/व्यवसाय आणि पत्ता
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            : {record1.occupation_detail}
                          </div>
                        </div>
                      </div>

                      {/* Family Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Family Information/कौटुंबिक माहिती</h6>
                        </div>
                        <div
                          className=" col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father First Name(English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_mname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father Middle Name(English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_fathermname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>Father Last Name(English)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.en_lname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे नाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fatherfname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे मधले नाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              :{record1.mr_fathermname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-6 col-md-4 para">
                              <p>वडिलांचे आडनाव(मराठी)</p>
                            </div>
                            <div className="col-6 col-md-8">
                              : {record1.mr_fatherlname}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 para">
                              <p>Farm in Acar/शेत जमीन एकर</p>
                            </div>
                            <div className="col-6">: {record1.farm_ac}</div>
                          </div>
                          <div className="col-12 col-md-6 d-flex">
                            <div className="col-6 para">
                              <p>Farm in Guntha/शेत जमीन गुंठे</p>
                            </div>
                            <div className="col-6">: {record1.farm_g}</div>
                          </div>
                          <div className="col-12 d-flex">
                            <div className="col-6 col-md-3 para">
                              <p>Other Property/इतर मालमत्ता</p>
                            </div>
                            <div className="col-6 col-md-9">
                              : {record1.other_property}
                            </div>
                          </div>
                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-6">
                            <div className="col-12 para">
                              <p>Brother/भाऊ:</p>
                            </div>
                            <div
                              className="d-flex"
                              style={{ textAlign: 'center' }}
                            >
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.b_married}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="col-12 para">
                              <p>Sister/बहीण:</p>
                            </div>
                            <div
                              className="d-flex"
                              style={{ textAlign: 'center' }}
                            >
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.s_married}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Contact Information */}
                      <div
                        className="row m-0 border mt-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>Contact Information/संपर्क माहिती</h6>
                        </div>
                        <div
                          className="col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-7 d-flex">
                            <div className="col-3  para">
                              <p>Address(English)</p>
                            </div>
                            <div className="col-9 ps-2">
                              : {record1.en_address}{' '}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 d-flex">
                            <div className="col-4 para ms-md-2">
                              <p>Village/City(English)</p>
                            </div>
                            <div className="col-8 ps-3">
                              : {record1.en_village}
                            </div>
                          </div>
                          <div className="col-12 col-md-7 d-flex">
                            <div className="col-3 para">
                              <p>पत्ता(मराठी)</p>
                            </div>
                            <div className="col-9 ps-2">
                              : {record1.mr_address}
                            </div>
                          </div>
                          <div className="col-12 col-md-5 d-flex">
                            <div className="col-4 para ms-md-2">
                              <p>गाव/शहर(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.mr_village}{' '}
                            </div>
                          </div>
                        </div>

                        <div
                          className=" col-12 p-2 d-flex"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>State(English)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.stateenname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>District(English)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.districtenname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>Taluka(English)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.talukaenname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>राज्य(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.statemrname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>जिल्हा(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.districtmrname}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p>तालुका(मराठी)</p>
                            </div>
                            <div className="col-8 ps-2">
                              : {record1.talukamrname}
                            </div>
                          </div>
                        </div>
                        <div
                          className=" col-12 d-flex p-2"
                          style={{ flexWrap: 'wrap' }}
                        >
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.email_id}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Mobile No-1/मोबाईल नंबर-1
                              </p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.mobileno}</p>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 d-flex">
                            <div className="col-4 para">
                              <p style={{ lineHeight: '18px' }}>
                                Mobile No-2/मोबाईल नंबर-2
                              </p>
                            </div>
                            <div className="col-8">
                              <p>: {record1.mobileno2}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Expectations about spouse */}
                      <div
                        className="row m-0 border mt-3 mb-3"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <div className="col-12 border-bottom" style={info}>
                          <h6>
                            Expectations About Spouse/जोडीदाराबद्दल अपेक्षा
                          </h6>
                        </div>
                        <div className=" col-12 p-2">
                          <div className="row d-flex">
                            <div className="col-5 col-md-3  para">
                              <p>Expectations/अपेक्षा:</p>
                            </div>
                            <div className="col-7 col-md-9">
                              {' '}
                              <p>
                                {record1.exp_housewife === 1 ? 'गृहिणी' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_compatible === 1 ? 'अनुरूप' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_farmer === 1 ? 'शेती' : null}{' '}
                                {record1.exp_serviceman === 1 ? 'नोकरी' : null}{' '}
                                &nbsp;{' '}
                                {record1.exp_businessman === 1
                                  ? 'व्यवसाय'
                                  : null}{' '}
                                &nbsp;{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Aadhar Check Checkbox */}
              <div className="row p-0 m-0">
                <>
                  <div className="col-12 col-sm-4"></div>
                  <div
                    className="col-12 col-sm-2 text-center p-1"
                    style={{ backgroundColor: '#462f46', color: 'white' }}
                  >
                    <label className="pe-3">Aadhar Check</label>
                    <input
                      type="checkbox"
                      checked={aadharCheckbox}
                      onChange={() => handleCheckboxChange('aadhar')}
                    />
                  </div>

                  <div
                    className="col-12 col-sm-2 text-center p-1"
                    style={{ backgroundColor: '#462f46', color: 'white' }}
                  >
                    <label className="pe-3">Payment Check</label>
                    <input
                      type="checkbox"
                      checked={paymentCheckbox}
                      onChange={() => handleCheckboxChange('payment')}
                    />
                  </div>
                  <div className="col-12 col-sm-4"></div>
                </>
              </div>

              {showOperationButtons && (
                <div className="operation-buttons">
                  <button
                    className="button1"
                    onClick={() => handleOperationClick('approve')}
                    disabled={!aadharCheckbox || !paymentCheckbox}
                  >
                    Payment Approve
                  </button>
                  <button
                    className="button2"
                    onClick={() => handleOperationClick('reject')}
                    // disabled={!aadharCheckbox || !paymentCheckbox}
                  >
                    Payment Reject
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={footerStyle} className="mt-5 fixed-bottom">
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  )
}

export default PaymentApproval

import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import Logo from '../../assets/logo2.png'
import Logo2 from '../../assets/kolLogo.png'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


function TermsAndCondition() {

  const navigate=useNavigate()
   // {----------------logout-------------------}
  //  const logoutFun = () => {
  //   sessionStorage.removeItem("userdata");
  //   navigate("/");    
  // }

  return (
 <div>
    <div>
    <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center",color:"rgb(57, 29, 28)" }}>
          <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
              <h5 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h5>
              <h4 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h4>
              <h5 className='text-center fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
            </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

        {/* <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>       */}
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "rgb(57, 29, 28)"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(57, 29, 28)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* <Nav.Link href="/" className=' mx-2 text-white'>Login</Nav.Link> */}
            {/* <Nav.Link href="/#/registration1" className=' mx-2 text-white'>Registration</Nav.Link> */}
            <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>terms & Condition</Nav.Link>
            <Nav.Link href="/#/privacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </div>
    <div className='p-md-4 p-3 mx-md-5'>
      <Nav.Link href="/terms&Condition" style={{color: "rgb(134, 28, 18)"}}><h3><b>Terms & Conditions</b></h3></Nav.Link>
      <p>
      These terms and conditions (“Terms and Conditions”) control your use of this website: www. jainboardingkolhapur.com (Website).
      </p>
      <p>
      You refers to a user or a paying customer. If You are a company or another person who gives access to Company products, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.
      </p>
      <p>
      By using or accessing the information on this website, including, but not limited to downloading or accessing courses through this Website or through designated Software of the Company; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:
      </p>
      <h5>GENERAL TERMS OF USE</h5>
      <p>
      Registering at this Website does not entitle You to the right of admission/selection for any course. The Company Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.
      </p>
      <p>
      Selected candidates are required to comply with the rules laid down under the various Policies and jainboarding kolhapur, reserves the right to expel a student or terminate the candidature at any time if found otherwise.
      </p>
      <p>
      As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.
      </p>
      <p>
      You will provide current, complete, accurate information in the registration section of this Website. You will further update and keep that information current as needed. Email login must be a valid email address maintained by You. You are solely responsible for maintaining the confidentiality of your password and account information. You will immediately notify the Company of any unauthorized account activity, or any unauthorized use of your email list(s) or any other breach of security You become aware of by emailing at: djbkkop@gmail.com
      </p>
      <p>
      Excluding third party course materials, jainboarding kolhapur owns all copyrights, patents, trade secrets, trademarks and other intellectual property rights, title and interest in or pertaining to the courses in the course catalogue and all other services developed by jainboarding kolhapur for the purpose of these courses on offer. No resale, transfer or re-export of the course(s) and/or content is authorized without the prior authorization of jainboarding kolhapur.
      </p>
      <p>
      jainboarding kolhapur reserves the right to change course prices at any time. In the event of such change, confirmed registrations will be honored at the original price. Changes to Service Tax and other tax and duty components will be incorporated into the Course Fee as and when such changes occur and the student will become liable to pay the incremental fee.
      </p>
      <p>
      Course content for all the courses on offer is provided for the purpose of education and guidance only. The course content is regularly reviewed and is subjected to change without notice. jainboarding kolhapur reserves the right to modify training content without notice.
      </p>
      <p>
      jainboarding kolhapur may terminate your access at any time for any reason. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. jainboarding kolhapur may also monitor access to this Website.
      </p>
      <p>
      To the extent allowed by local law, in no event will jainboarding kolhapur or its affiliates, subcontractors or suppliers be liable for any incidental, indirect, special consequential damages.
      </p>
      <p>
      By registering on this Website, You give your consent to receive, from time to time, text messages from jainboarding kolhapur which may include offers from Us or our affiliates and partners.
      </p>
      <h5>ONLINE PAYMENT TERMS</h5>
      <p>
      The following section states the Terms and Conditions which will be applicable to the user using the Company’s on-line payment process for various courses/services offered by Us. Please read this page carefully. If these Terms & Conditions are not acceptable to You, please do not use this process. By using Company’s online payment process, You are indicating your acceptance to be bound by these Terms & Conditions. jainboarding kolhapur reserves the right to revise these Terms & Conditions from time to time.
      </p>
      <p>
      User information entered during the online payment process is kept secure and confidential and will not be divulged to any third party, other than the payment gateway service provider.
      </p>
      <p>
      The Company will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold the Company harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from incorrect use of the online payment process or any other causes attributable to the user.
      </p>
      <p>
      The Company will not be responsible for any refunds arising out of any failures in online payments in case the amount gets deducted from user’s saving/current account and does not get credited in the Company’s account, before the transaction is complete. Users in this case will have to contact either the Bank or Us to clarify the same.
      </p>
      <p>
      In no event will the user be eligible for the refund of the payment made online.
      </p>
      <p>
      All the refunds of credit & debit card transactions, which are subject to the Company’s policy, will be made by cheques only; no refund will be processed either by online transfer or in cash.
      </p>
      <p>
      Receipts of online payments along with confirmation of admissions by jainboarding kolhapur should be maintained by the user safely, as this will be a pre-condition for processing any refund.
      </p>
      <p>
      Successful completion of the online application process and payment alone will not make the user eligible for the admission. Since the online application system does not perform any check on the qualification of user or other essential conditions during the application process, hence, successful completion of the online application process may not make the user eligible for the course admission. The course admission will be totally at the discretion of jainboarding kolhapur and the same will be confirmed upon scrutiny of the admission details provided by the user and jainboarding kolhapur is not liable to give reason for any rejection.
      </p>
      <p>
      It is the user’s responsibility to ensure payment of full fees as applicable. Such payment of fees by or on behalf of the user does not in itself constitute acceptance of user’s candidature for the course. When the user successfully completes the online payment transaction using credit/debit cards option, funds will be deducted from the credit/debit card mentioned by the user and the payments will be credited to the Company. Any payment received through this website will be treated as having been made by the user, even though the same may have been made by some other person/party, in such event, the Company shall not be liable to entertain any correspondence from any person other than the user’s parents/guardian.
      </p>
      <p>
      In case the user registers successfully but fails to pay the required fee on or before the stipulated date, such application will stand cancelled without any further notification.
      </p>
      <p>
      The Company shall not be liable to protect data from third parties if there is any failure on the part of the user or any person acting on behalf of the user, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process.
      </p>
      <p>
      The Company will be entitled to use the information given during the online payment process for the purpose of registration of the user and also for statistical purposes or for its internal use.
      </p>
      </div>
      <div>
      <UserMasterFooter/>
      </div>
    </div>
   )
} 

export default TermsAndCondition

